import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Button from "../../../components/button";
import Modal from "../../../components/modal";
import Input from "../../../components/input";
import LoadingSpinner from '../../../components/loading-spinner';

import {
    clear_city,
    create_city,
    CREATE_CITY,
    delete_city,
    DELETE_CITY,
    get_all_city,
    update_city,
    UPDATE_CITY
} from "../../../redux/cities";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Cities = (props) => {
    let history = useHistory();
    let dispatch = useDispatch();

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const _get_all_city = useSelector((state) => state.city_module._get_all_city);
    const _create_city = useSelector((state) => state.city_module._create_city);
    const _update_city = useSelector((state) => state.city_module._update_city);
    const _delete_city = useSelector((state) => state.city_module._delete_city);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (_get_all_city.data.datos) {
            setData(_get_all_city.data.datos);
        }
    }, [_get_all_city]);

    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);
    const [modal, setModal] = useState(false);
    const [modal_delete, setModalDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [id, setId] = useState(0);

    const [form, setForm] = useState({
        nombre: "",
    });

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);
        }
    }, [props]);


    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_cities').length > 0) {
                act.push({
                    name: "edit",
                    label: "Edit",
                    icon: "bx bx-edit",
                    color: "primary",
                    onClick: (item) => {
                        setModal(true);
                        setId(item.id);
                        setForm({...form, nombre: item.nombre});
                    },
                })

            }
            if (permisos.filter(x => x.codigo === 'delete_cities').length > 0) {
                act.push({
                    name: "delete",
                    label: "Eliminar",
                    icon: "bx bxs-trash",
                    color: "danger",
                    onClick: (item) => {
                        setModalDelete(true);
                        setId(item.id)
                    },
                })

            }
        }
        setActions(act);
        // eslint-disable-next-line
    }, [permisos, history]);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        if (_create_city.status === 201) {
            toast.success("Creado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_city(CREATE_CITY));
            dispatch(get_all_city(token));
            setIsLoading(false);
            setModal(false);
        } else if (_create_city.status !== 0) {
            dispatch(clear_city(CREATE_CITY));
            toast.error(_create_city.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_create_city, dispatch, history, token, id]);

    useEffect(() => {
        if (_update_city.status === 200) {
            toast.success("Actualizado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_city(UPDATE_CITY));
            dispatch(get_all_city(token));
            setIsLoading(false);
            setModal(false);
            setId(0);
        } else if (_update_city.status !== 0) {
            dispatch(clear_city(UPDATE_CITY));
            toast.error(_update_city.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_update_city, dispatch, history, token, id]);

    useEffect(() => {
        if (_delete_city.status === 200) {
            toast.success("Eliminado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_city(DELETE_CITY));
            dispatch(get_all_city(token));
            setModalDelete(false);
            setId(0);
        } else if (_delete_city.status !== 0) {
            dispatch(clear_city(DELETE_CITY));
            toast.error(_delete_city.message);
        }
    }, [_delete_city, history, token, dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (id === 0) {
            dispatch(create_city(token, form));
        } else {
            dispatch(update_city(token, id, form));
        }
    };

    const handleDelete = () => {
        dispatch(delete_city(token, id));
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Lista"
                            items={[{label: "Ciudades", link: "/ciudades"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div><ToastContainer/></div>
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                {
                                    permisos.filter(x => x.codigo === 'add_cities').length > 0 ?
                                        <Button type="button" text="Crear" theme="calendar" onClick={() => {
                                            setModal(true);
                                            setForm({...form, nombre: ''});
                                            setId(0);
                                        }}/> : null
                                }
                                <div className="table-responsive">
                                    <DataTable
                                        dataTable={data}
                                        columns={[
                                            {
                                                name: "nombre",
                                                label: "Nombre",
                                                type: "text",
                                            },
                                            {
                                                name: "fecha_creacion",
                                                label: "Fecha de creación",
                                                type: "text",
                                            },
                                            {
                                                name: "activo",
                                                label: "Activo",
                                                type: "boolean",
                                            },
                                        ]}
                                        actions={actions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main div-modal" show={modal}>
                <form onSubmit={handleSubmit}>
                    {isLoading && <LoadingSpinner/>}
                    <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">{id === 0 ? 'Crear ' : 'Editar '} ciudad</h5>
                        <div className="form-group">
                            <Input
                                label="Nombre"
                                name="nombre"
                                value={form.nombre}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="card-footer text-center">
                        <button onClick={() => {
                        }} className={`btn btn-calendar mb-1 button_div`}>Guardar
                        </button>
                        <button type="button" onClick={() => {
                            setModal(false);
                        }} className={`btn btn-outline-calendar button_div`}>Cancelar
                        </button>
                    </div>
                </form>
            </Modal>
            <Modal className="modal-main div-modal" show={modal_delete}>
                <div className="modal-content">
                    <div className="card-body text-center">
                        <h5>¿Está seguro de eliminar o inactivar el registro?</h5>
                    </div>
                    <div className="card-footer text-center">
                        <button type="button" className="btn btn-calendar mb-1 button_div" onClick={() => {
                            handleDelete()
                        }}>
                            SI
                        </button>
                        <button type="button" className="btn btn-outline-calendar button_div" onClick={() => {
                            setModalDelete(false)
                        }}>
                            NO
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Cities;
