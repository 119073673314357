import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from "../../../components/loading-spinner";
import {URLAPI} from "../../../config";
import Modal from "../../../components/modal";
import Input from "../../../components/input";
import SelectInput from "../../../components/select";
import {search_items, search_values_attributes} from "../../../redux/items";
import {
    get_all_shopping_cart,
    CREATE_SHOPPING_CART_SUCCESS,
    create_shopping_cart,
    clear_status_shopping_cart
} from "../../../redux/shopping_carts";

const Item = (props) => {
    const {id} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [form, setForm] = useState({
        codigo_interno: "",
        titulo: "",
        descripcion: "",
        familia: "",
        id_genero: 0,
        genero: '',
        id_categoria: 0,
        categoria: '',
        id_fabricante: 0,
        fabricante: '',
        activo: false,
        imagenes: [],
        variantes: [],
        valores_atributos: [],
    });

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getItems();
        // eslint-disable-next-line
    }, []);

    async function getItems() {
        setIsLoading(true);
        const params = '?id=' + id;
        const response = await search_items(token, params);
        if (response.status === 200) {
            console.log(response.data.datos[0])
            setForm({
                codigo_interno: response.data.datos[0].codigo_interno,
                titulo: response.data.datos[0].titulo,
                descripcion: response.data.datos[0].descripcion,
                familia: response.data.datos[0].familia,
                id_genero: response.data.datos[0].id_genero,
                genero: response.data.datos[0].genero,
                id_categoria: response.data.datos[0].id_categoria,
                categoria: response.data.datos[0].categoria,
                id_fabricante: response.data.datos[0].id_fabricante,
                fabricante: response.data.datos[0].fabricante,
                activo: response.data.datos[0].activo,
                imagenes: response.data.datos[0].imagenes,
                variantes: response.data.datos[0].variantes,
                valores_atributos: response.data.datos[0].valores_atributos,
            });
        }
        setIsLoading(false);
    }

    //const [radio, setRadio] = useState(0);
    const [details, setDetails] = useState([]);
    const [detailValues, setDetailValues] = useState([]);
    const [modalDetail, setModalDetail] = useState(false);
    const [modalConfirm, setModalConfirm] = useState(false);
    const [idAttributeValue, setIdAttributeValue] = useState(0);

    async function getAttributeValue(id_attribute_value, id_attribute_value_fiter, atributo, y, index) {
        setIsLoading(true);
        const params = '?id_valor_atributo=' + id_attribute_value + '&&atributo=' + atributo + '&&id_item=' + id + '&&id_valor_atributo_filtro=' + id_attribute_value_fiter;
        console.log(params);
        const response = await search_values_attributes(token, params);
        if (response.status === 200) {
            form.valores_atributos[y][index + 1].valores = response.data.datos
        }
        setIsLoading(false);
    }

    const handleChange = (e, y, index) => {
        if (e.target.name !== 'Cantidad') {
            if (index === 0) {
                setIdAttributeValue(parseInt(e.target.value));
            }

            if (index + 1 < form.valores_atributos[y].length) {
                // eslint-disable-next-line
                form.valores_atributos[y].map((item, a) => {
                    if (a > index) {
                        item.valores = []
                    }
                })
                getAttributeValue(index === 0 ? parseInt(e.target.value) : idAttributeValue, parseInt(e.target.value), form.valores_atributos[y][index + 1].nombre, y, index)
            }
        }

        let value_attribute = e.target.value

        if (e.target.name !== 'Cantidad') {
            // eslint-disable-next-line
            form.variantes.map((a) => {
                // eslint-disable-next-line
                a.valores.map((b) => {
                    if (b.nombre_atributo === e.target.name && b.id === parseInt(e.target.value)) {
                        value_attribute = b.nombre;
                    }
                })
            })
        }

        let value = details.filter(x => x.nombre === e.target.name)
        if (value.length === 0) {
            if (e.target.name === 'Cantidad') {
                setDetails([
                    ...details,
                    {'nombre': e.target.name, 'valor': value_attribute}
                ])
            } else {
                setDetails([
                    ...details,
                    {'id_valor_atributo': parseInt(e.target.value), 'nombre': e.target.name, 'valor': value_attribute}
                ])
            }
        } else {
            if (e.target.name !== 'Cantidad') {
                value[0]['id_valor_atributo'] = parseInt(e.target.value);
            }
            value[0]['valor'] = value_attribute;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let c = 0;
        // eslint-disable-next-line
        form.valores_atributos.map((x) => {
            c = x.length;
        })
        if (details.length !== c + 1) {
            toast.error("Faltan ingresar datos", {position: toast.POSITION.TOP_RIGHT});
            return;
        }
        setDetailValues([
            ...detailValues,
            details
        ])
        setModalDetail(false);
    };

    const _create_shopping_cart = useSelector((state) => state.shopping_cart_module._create_shopping_cart);

    useEffect(() => {
        if (_create_shopping_cart.status === 201) {
            dispatch(clear_status_shopping_cart(CREATE_SHOPPING_CART_SUCCESS));
            setModalConfirm(false);
            dispatch(get_all_shopping_cart(token));
            history.push(`/carrito-compra`);
            document.getElementById("catalogo").className = "type_text text_black text_16";
            document.getElementById("cotizaciones").className = "type_text text_black text_16";
            document.getElementById("carrito").className = "type_text text_black text_16 active";
        } else if (_create_shopping_cart.status !== 0) {
            dispatch(clear_status_shopping_cart(CREATE_SHOPPING_CART_SUCCESS));
            toast.error(_create_shopping_cart.message, {position: toast.POSITION.TOP_RIGHT});
        }
        setIsLoading(false);
    }, [_create_shopping_cart, dispatch, history, token]);

    function saveShoppingCart() {
        setIsLoading(true);
        let variantes = [];
        // eslint-disable-next-line
        form.variantes.map((x) => {
            let valores = [];
            // eslint-disable-next-line
            x.valores.map((y) => {
                valores.push(y.id);
            })
            variantes.push({'id_variante': x.id, 'valores': valores})
        })
        let detalles = [];
        // eslint-disable-next-line
        detailValues.map((a) => {
            let result = {'qty': 0, 'valores': []};
            // eslint-disable-next-line
            a.map((b) => {
                if (b.nombre === 'Cantidad') {
                    result['qty'] = b.valor;
                } else {
                    result['valores'].push(b.id_valor_atributo);
                }
            })
            detalles.push(result);
        })
        const data = [];
        // eslint-disable-next-line
        detalles.map((n) => {
            let filter = variantes.filter(c => JSON.stringify(c.valores) === JSON.stringify(n.valores));
            if (filter.length > 0) {
                data.push({'qty': n.qty, 'variante': filter[0].id_variante});
            }
        })

        dispatch(create_shopping_cart(token, {'valores': JSON.stringify(data)}));
    }

    return (
        <div className="color box_shadow">
            <div className="p-1">
                <div className="card-body">
                    {isLoading && <LoadingSpinner/>}
                    <div><ToastContainer/></div>
                    <p className="type_text text_20 text_black">{(form.familia !== '') && (form.familia + ' / ' + form.categoria)}</p>
                    <div className="row mt-2">
                        <div className="col-md-6 col-sm-12 text-center">
                            {(form.imagenes.length > 0) && (
                                <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
                                    <div class="carousel-inner">
                                        {form.imagenes.map((x, index) => (
                                            <div class={(index === 0 ? "carousel-item active" : "carousel-item")}>
                                                <img
                                                    src={URLAPI + x.url}
                                                    class="d-block w-100 div_image1"
                                                    alt="Imagen"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleSlidesOnly" role="button"
                                       data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#carouselExampleSlidesOnly" role="button"
                                       data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </div>
                            )}
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <p className="type_text text_32 text_color">{form.titulo}</p>
                            <p className="type_text text_16 text_black mt-0">{form.descripcion}</p>
                            <p className="type_text text_16 text_black mt-0">Código: {form.codigo_interno}</p>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <p className="type_text text_20 text_color">Detalles</p>
                                </div>
                                <div className="col-md-6 col-sm-12" style={{textAlign: 'end'}}>
                                    <button type="button" className="btn btn-calendar-cliente1" onClick={() => {
                                        setModalDetail(true);
                                        setDetails([]);
                                        document.getElementById("Cantidad").value = '';
                                        // eslint-disable-next-line
                                        form.valores_atributos.map((row) => {
                                            // eslint-disable-next-line
                                            row.map((x, a) => {
                                                if (a !== 0) {
                                                    x.valores = [];
                                                } else {
                                                    document.getElementById(x.nombre).selectedIndex = 0;
                                                }
                                            })
                                        })
                                    }}>Agregar detalle
                                    </button>
                                </div>
                                {(detailValues.length > 0) ? (
                                    <div className="col-12">
                                        {detailValues.map((row, index) => (
                                            <div className="row border_card_1 m-1">
                                                <div className="col-12 mt-1 text-right">
                                                    {/* eslint-disable-next-line */}
                                                    <a href="#" onClick={() => {
                                                        const v = detailValues.indexOf(index);
                                                        detailValues.splice(v, 1);
                                                    }}>
                                                        <img
                                                            className="logo"
                                                            src="../../../base/app-assets/images/icon/delete.png"
                                                            alt="Eliminar"
                                                            width={30}
                                                            height={30}
                                                        />
                                                    </a>
                                                </div>
                                                {row.map((x, y) => (
                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group">
                                                            <Input
                                                                label={x.nombre}
                                                                value={x.valor}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="col-12 border_card mt-2">
                                        <p className="type_text text_black text_inter text_16 m-2"
                                           style={{padding: 10, width: '100%'}}>Debes agregar al menos un detalle</p>
                                    </div>
                                )}
                            </div>
                            <div className="card-footer d-flex justify-content-center">
                                <button type="button" className="btn btn-outline-calendar-cliente1 mr-2"
                                        onClick={() => {
                                            history.push(`/familia/${form.familia}`)
                                        }}>Regresar
                                </button>
                                <button type="button" className="btn btn-calendar-cliente1" onClick={() => {
                                    if (detailValues.length > 0) {
                                        setModalConfirm(true);
                                    } else {
                                        toast.error("Debe ingresar al menos un detalle", {position: toast.POSITION.TOP_RIGHT});
                                        return;
                                    }
                                }}>Agregar al carrito
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="modal-main div-modal" show={modalDetail}>
                    {isLoading && <LoadingSpinner/>}
                    <form onSubmit={handleSubmit}>
                        <div className="card-body">
                            <h5 className="titulo_modal type_text mb-2">Características a elegir</h5>
                            <div className="form-group">
                                <Input
                                    type="number"
                                    id="Cantidad"
                                    label="Cantidad"
                                    name="Cantidad"
                                    onChange={handleChange}
                                />
                            </div>
                            {(form.valores_atributos.length > 0) && (
                                form.valores_atributos.map((row, y) => (
                                    row.map((x, index) => (
                                        <div className="form-group">
                                            <small className="texto2">{x.nombre}</small>
                                            <SelectInput
                                                id={x.nombre}
                                                label={x.nombre}
                                                name={x.nombre}
                                                onChange={(e) => handleChange(e, y, index)}
                                                options={(x.valores) ? x.valores.map((item) => ({
                                                    name: item.nombre,
                                                    id: item.id,
                                                })) : []}
                                            />
                                        </div>
                                    ))
                                ))
                            )}
                        </div>
                        <div className="card-footer text-center">
                            <button type="submit" className={`btn btn-calendar-cliente1 mb-1 button_div`}>Agregar
                                detalle
                            </button>
                            <button type="button" onClick={() => {
                                setModalDetail(false);
                            }} className={`btn btn-outline-calendar-cliente1 button_div`}>Cancelar
                            </button>
                        </div>
                    </form>
                </Modal>
                <Modal className="modal-main div-modal" show={modalConfirm}>
                    {isLoading && <LoadingSpinner/>}
                    <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">Agregar a carrito</h5>
                        <div className="text-center">
                            <p className="type_text text_16 text_black">Al confirmar se agregará a tu carrito la joya
                                seleccionada con sus detalles</p>
                        </div>
                    </div>
                    <div className="card-footer text-center">
                        <button type="button" onClick={() => {
                            saveShoppingCart()
                        }} className={`btn btn-calendar-cliente1 mb-1 button_div`}>Confirmar
                        </button>
                        <button type="button" onClick={() => {
                            setModalConfirm(false);
                        }} className={`btn btn-outline-calendar-cliente1 button_div`}>Cancelar
                        </button>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default Item;
