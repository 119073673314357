import React from "react";
import {WEBSOCKET} from "./index";

export const socket = (username) => {
    if (username) {
        const socket = new WebSocket(
            WEBSOCKET + "/ws/websocket/" + username + "/"
        );
        return socket;
    }

}
export const SocketContext = React.createContext({
    socket: socket(""),
});


export const TYPE_UPDATE_MESSAGE = "update_message";
export const TYPE_CREATE_MESSAGE = "create_message";
export const TYPE_CREATE_NOTIFICATIONS = "create_notifications";
export const TYPE_REPORT_GENERATE = "report_generate";
export const TYPE_REPORT_ORDER_GENERATE = "report_generate_order";
