import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import LoadingSpinner from "../../../components/loading-spinner";
import {URLAPI} from "../../../config";
import {
    search_catalogue_items,
    search_values_family
} from "../../../redux/items";
import Modal from "../../../components/modal";
import SelectInput from "../../../components/select";
import FooterClient from "../../../components/footer_client";
import {ReactComponent as ReactCancel} from '../../../cancel.svg';

const Products = (props) => {
    const {family} = useParams();
    const history = useHistory();

    const {token} = useSelector((state) => state.users_module.login.data) || null;
    // const _get_all_family = useSelector(
    //     (state) => state.family_module._get_all_family
    // );
    //
    // const _get_all_banner_image = useSelector(
    //     (state) => state.banner_image_module._get_all_banner_image
    // );
    //
    // const [families, setFamilies] = useState([]);
    // const [images, setImages] = useState([]);
    // // const [family, setFamily] = useState('');

    useEffect(() => {
        getCatalogueItems(1);
        // eslint-disable-next-line
    }, [family]);

    // useEffect(() => {
    //     if (_get_all_banner_image && _get_all_banner_image.data && _get_all_banner_image.data.datos) {
    //         let values = _get_all_banner_image.data.datos.filter((x) => x.activo === true);
    //         setImages(values);
    //     }
    // // eslint-disable-next-line
    // }, [_get_all_banner_image]);

    // const [index, setIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [modal_filter, setModalFilter] = useState(false);
    const [modal_order, setModalOrder] = useState(false);
    const [datas, setDatas] = useState([]);

    const [optionsFilter, setOptionsFilter] = useState([]);
    const [optionsAttributes, setOptionsAttributes] = useState([]);
    const [attribute, setAttribute] = useState(null);
    const [value_attribute, setValueAttribute] = useState(null);
    const [page, setPage] = useState(1);
    const [n_page, setNPage] = useState(0);


    async function getCatalogueItems(page) {
        setIsLoading(true);
        const params = '?familia=' + family + '&page=' + page;
        const response = await search_catalogue_items(token, params);
        if (response.status === 200) {
            setDatas(response.data.datos);
            setNPage(response.data.n_page);
        }
        setIsLoading(false);
    }

    function more_data() {
        let count = page + 1;
        setPage(count)
        getCatalogueItems(count);
    }


    function less_data() {
        let count = page - 1;
        setPage(count)
        getCatalogueItems(count);
    }

    const filter_options = [
        {name: "Categoría", id: 1},
        {name: "Género", id: 2},
        {name: "Atributos", id: 3},
    ];

    const [filtros, setFiltros] = useState({
        categoria: false,
        genero: false,
        atributos: false
    });

    const [form, setForm] = useState({
        genero: '',
        categoria: '',
        atributos: '',
    });

    const changeFilter = (e) => {
        setAttribute(null);
        if (e.target.value === '0') {
            setFiltros({
                categoria: false,
                genero: false,
                atributos: false
            });
            document.getElementById("filtro").selectedIndex = 0;
        }
        if (e.target.value === '1' || e.target.value === '2') {
            let params = '?familia=' + family;
            if (e.target.value === '1') {
                setFiltros({
                    categoria: true,
                    genero: false,
                    atributos: false
                });
                params += '&&filtro=categoria';
            }
            if (e.target.value === '2') {
                setFiltros({
                    categoria: false,
                    genero: true,
                    atributos: false
                });
                params += '&&filtro=genero';
            }
            return new Promise((resolve, reject) => {
                search_values_family(token, params).then((res) => {
                    setOptionsFilter(res.data.datos);
                });
            });
        }

        if (e.target.value === '3') {
            setFiltros({
                categoria: false,
                genero: false,
                atributos: true
            });
            let params = '?familia=' + family + '&&filtro=atributos';
            return new Promise((resolve, reject) => {
                search_values_family(token, params).then((res) => {
                    setOptionsFilter(res.data.datos);
                });
            });
        }
    }

    const handleChangeCategory = (e) => {
        setForm({
            ...form,
            categoria: e.target.value,
        });
    }

    const handleChangeGender = (e) => {
        setForm({
            ...form,
            genero: e.target.value,
        });
    }

    const handleChangeOptionAttribute = (e) => {
        let filter = optionsFilter.filter(x => x.id === parseInt(e.target.value));
        setAttribute(filter[0].nombre);
        setOptionsAttributes(filter[0].valores);
        if (attribute) {
            document.getElementById("attribute_id").selectedIndex = 0;
        }
    }

    const handleChangeAttribute = (e) => {
        setValueAttribute(e.target.value);
        setForm({
            ...form,
            atributos: attribute + ': ' + e.target.value.toString(),
        });
    }

    const add_filter = () => {
        setModalFilter(false);
        let param_filter = "?familia=" + family;
        if (form.categoria) {
            param_filter += "&&categoria=" + form.categoria;
        }
        if (form.genero) {
            param_filter += "&&genero=" + form.genero;
        }
        if (form.atributos) {
            param_filter += "&&atributos=" + JSON.stringify({'atributo': attribute, 'valor': value_attribute});
        }

        return new Promise((resolve, reject) => {
            search_catalogue_items(token, param_filter).then((res) => {
                setDatas(res.data.datos);
            });
        });
    }

    const delete_filter = (value) => {
        let param = "?familia=" + family;

        if (value === 'categoria') {
            setForm({...form, categoria: ''});
            if (form.genero) {
                param += "&&genero=" + form.genero;
            }
            if (attribute) {
                param += "atributos=" + JSON.stringify({'atributo': attribute, 'valor': value_attribute});
            }
        }
        if (value === 'genero') {
            setForm({...form, genero: ''});
            if (form.categoria) {
                param += "&&categoria=" + form.categoria;
            }
            if (attribute) {
                param += "atributos=" + JSON.stringify({'atributo': attribute, 'valor': value_attribute});
            }
        }
        if (value === 'atributos') {
            setForm({...form, atributos: ''});
            if (form.categoria) {
                param += "&&categoria=" + form.categoria;
            }
            if (form.genero) {
                param += "&&genero=" + form.genero;
            }
        }
        return new Promise((resolve, reject) => {
            search_catalogue_items(token, param).then((res) => {
                setDatas(res.data.datos);
            });
        });
    }

    const order_options = [
        {name: "Título ascendente", id: 1},
        {name: "Título descendente", id: 2},
        {name: "Fecha de creación", id: 3},
    ];


    const [order, setOrder] = useState(0);

    const changeOrder = (e) => {
        setOrder(parseInt(e.target.value));
    }

    const add_order = () => {
        if (order === 0) {
            getCatalogueItems(1);
        }
        if (order === 1) {
            // eslint-disable-next-line
            const ordenado = datas.sort(function (x, y) {
                let a = x.titulo.toUpperCase(),
                    b = y.titulo.toUpperCase();
                return a === b ? 0 : a > b ? 1 : -1;
            });
            setDatas(ordenado);
        }
        if (order === 2) {
            // eslint-disable-next-line
            const ordenado = datas.sort(function (x, y) {
                let a = x.titulo.toUpperCase(),
                    b = y.titulo.toUpperCase();
                return a === b ? 0 : b > a ? 1 : -1;
            });
            setDatas(ordenado);
        }
        if (order === 3) {
            // eslint-disable-next-line
            const ordenado = datas.sort((a, b) => {
                return new Date(b.fecha_creacion) - new Date(a.fecha_creacion);
            });
            setDatas(ordenado);
        }
        setModalOrder(false);
    }

    return (
        <div className="color box_shadow">
            <div className="p-1">
                <div className="card-body">
                    {isLoading && <LoadingSpinner/>}


                    <div className="d-flex">
                        <div style={{marginRight: 10, cursor: 'pointer'}} onClick={() => {
                            history.push(`/`);
                        }}>
                            <ReactCancel width={32} heght={29}/>
                        </div>
                        <p className="type_text text_24 text_black"
                           style={{marginTop: 2, fontWeight: 'bold'}}>{family}</p>
                    </div>

                    {/*    <div className="mt-2 mb-3">*/}
                    {/*        <ul class="nav_client">*/}
                    {/*            {families.map((x, ind) => (*/}
                    {/*                <li>*/}
                    {/*                    /!*eslint-disable-next-line *!/*/}
                    {/*                    <a className={(ind === index) ? "type_text text_black text_16 active" : "type_text text_black text_16"}*/}
                    {/*                       style={{cursor: 'pointer'}} onClick={() => {*/}
                    {/*                        getCatalogueItems(x.nombre, 1);*/}
                    {/*                        setIndex(ind);*/}
                    {/*                        setFamily(x.nombre)*/}
                    {/*                    }}>{x.nombre}</a>*/}
                    {/*                </li>*/}
                    {/*            ))}*/}
                    {/*        </ul>*/}
                    {/*    </div>*/}
                    {/*    */}

                    <div className="m-1">
                        <div className="border_card pl-2 pt-2">
                            <button type="button" className="btn btn-calendar-cliente1 mr-2" onClick={() => {
                                setModalFilter(true);
                                setFiltros({
                                    categoria: false,
                                    genero: false,
                                    atributos: false
                                });
                                document.getElementById("filtro").selectedIndex = 0;
                                document.getElementById("atributo_id").selectedIndex = 0;
                                setAttribute(null);
                            }}>Filtros
                            </button>
                            <button type="button" className="btn btn-calendar-cliente1 margin-btn" onClick={() => {
                                setModalOrder(true);
                                document.getElementById("orden").selectedIndex = 0;
                            }}>Ordenar por
                            </button>
                            <div className="mt-2 div-item">
                                {(form.categoria) &&
                                    <li className="list-group-item d-flex justify-content-between align-items-center"
                                        style={{background: 'rgba(0, 0, 0, 0.16)', marginBottom: 5, borderRadius: 30}}>
                                        <div className="d-flex align-items-center">
                                            <div className="mr-1">
                                                <span
                                                    className="text-bold-500">{form.categoria}</span>
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            {/*eslint-disable-next-line*/}
                                            <a href="#" onClick={() => {
                                                delete_filter('categoria')
                                            }} className="btn-calendar-cliente1" style={{
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                                paddingTop: 5,
                                                paddingBottom: 5,
                                                borderRadius: 50,
                                                marginRight: 5
                                            }}>x</a>
                                        </div>
                                    </li>
                                }
                                {(form.genero) &&
                                    <li className="list-group-item d-flex justify-content-between align-items-center"
                                        style={{background: 'rgba(0, 0, 0, 0.16)', marginBottom: 5, borderRadius: 30}}>
                                        <div className="d-flex align-items-center">
                                            <div className="mr-1">
                                                <span
                                                    className="text-bold-500">{form.genero}</span>
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            {/*eslint-disable-next-line*/}
                                            <a href="#" onClick={() => {
                                                delete_filter('genero')
                                            }} className="btn-calendar-cliente1" style={{
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                                paddingTop: 4,
                                                paddingBottom: 5,
                                                borderRadius: 50,
                                                marginRight: 5
                                            }}>x</a>
                                        </div>
                                    </li>
                                }
                                {(form.atributos) &&
                                    <li className="list-group-item d-flex justify-content-between align-items-center"
                                        style={{background: 'rgba(0, 0, 0, 0.16)', marginBottom: 5, borderRadius: 30}}>
                                        <div className="d-flex align-items-center">
                                            <div className="mr-1">
                                                <span className="text-bold-500">{form.atributos}</span>
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            {/*eslint-disable-next-line*/}
                                            <a href="#" onClick={() => {
                                                delete_filter('atributos');
                                            }} className="btn-calendar-cliente1" style={{
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                                paddingTop: 4,
                                                paddingBottom: 5,
                                                borderRadius: 50,
                                                marginRight: 5
                                            }}>x</a>
                                        </div>
                                    </li>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {datas && (
                            datas.map((x) => (
                                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                    <div className="m-1 text-center border_card_1">
                                        {x.imagen ? (
                                            <div className="m-1 text-center">
                                                <img
                                                    src={URLAPI + x.imagen}
                                                    className="img-catalogue"
                                                    alt='Imagen'
                                                />
                                            </div>
                                        ) : (
                                            <div className="m-1 text-center div_container_image">
                                                <div className="div_image"/>
                                            </div>
                                        )}
                                        <p className="type_text text_black type_text text_16 mb-2">{x.descripcion}</p>
                                        <button className="btn btn-calendar-cliente1 mb-1" onClick={() => {
                                            history.push(`/ver/${x.id}`)
                                        }}>Cotizar
                                        </button>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                    {(n_page > 1) &&
                        <div className="row pl-1 pr-1" style={{marginTop: 10, marginBottom: 5}}>
                            <div className="col-4">
                                <label className="ml-1">Página {page}</label>
                            </div>
                            <div className="col-8 text-right">
                                {(page > 1 && page <= n_page) &&
                                    <button className="btn btn-calendar-cliente1 mr-1" type="button" onClick={() => {
                                        less_data();
                                    }}><i className="bx bx-arrow-back"></i></button>
                                }
                                {(page >= 1 && page < n_page) &&
                                    <button className="btn btn-calendar-cliente1" type="button" onClick={() => {
                                        more_data();
                                    }}><i className="bx bx-right-arrow-alt"></i></button>
                                }
                            </div>
                        </div>
                    }
                </div>
                <Modal className="modal-main div-modal" show={modal_filter}>
                    {isLoading && <LoadingSpinner/>}
                    <div className="modal-header">
                        <h5 className="titulo_modal type_text">Filtros</h5>
                        <button type="button" style={{marginRight: -24}}
                                className="btn" onClick={() => {
                            setModalFilter(!modal_filter);
                        }}> X
                        </button>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12">
                                <SelectInput
                                    id="filtro"
                                    name="filtro"
                                    options={filter_options}
                                    onChange={changeFilter}
                                />
                            </div>
                            {
                                filtros.categoria &&
                                <div className="col-12">
                                    <SelectInput
                                        label="Categoría"
                                        name="categoria"
                                        value={form.categoria}
                                        onChange={handleChangeCategory}
                                        options={optionsFilter ? optionsFilter.map((item) => ({
                                            name: item.nombre,
                                            id: item.nombre,
                                        })) : []}
                                    />
                                </div>
                            }
                            {
                                filtros.genero &&
                                <div className="col-12">
                                    <SelectInput
                                        label="Género"
                                        name="genero"
                                        value={form.genero}
                                        onChange={handleChangeGender}
                                        options={optionsFilter ? optionsFilter.map((item) => ({
                                            name: item.nombre,
                                            id: item.nombre,
                                        })) : []}
                                    />
                                </div>
                            }
                            {
                                filtros.atributos &&
                                <div className="col-12">
                                    <SelectInput
                                        label="Atributo"
                                        name="atributo"
                                        value={form.atributos}
                                        onChange={handleChangeOptionAttribute}
                                        options={optionsFilter ? optionsFilter.map((item) => ({
                                            name: item.nombre,
                                            id: item.id,
                                        })) : []}
                                    />
                                    {(attribute) &&
                                        <SelectInput
                                            id="attribute_id"
                                            label={attribute}
                                            name={attribute}
                                            onChange={handleChangeAttribute}
                                            options={optionsAttributes ? optionsAttributes.map((item) => ({
                                                name: item.nombre,
                                                id: item.nombre,
                                            })) : []}
                                        />
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div className="card-footer text-center">
                        <button type="button" className="btn btn-calendar-cliente1" onClick={() => {
                            add_filter()
                        }}>Aplicar
                        </button>
                    </div>
                </Modal>
                <Modal className="modal-main div-modal" show={modal_order}>
                    {isLoading && <LoadingSpinner/>}
                    <div className="modal-header">
                        <h5 className="titulo_modal type_text">Ordenar</h5>
                        <button type="button" style={{marginRight: -24}}
                                className="btn" onClick={() => {
                            setModalOrder(!modal_order);
                        }}> X
                        </button>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12">
                                <SelectInput
                                    id="orden"
                                    name="orden"
                                    options={order_options}
                                    onChange={changeOrder}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-center">
                        <button type="button" className="btn btn-calendar-cliente1" onClick={() => {
                            add_order()
                        }}>Aplicar
                        </button>
                    </div>
                </Modal>
            </div>
            <FooterClient/>
        </div>
    );
};

export default Products;
