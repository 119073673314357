import {
    URLAPI,
    SHOP_PATH,
    SHOP_CREATE_PATH,
    SHOP_UPDATE_PATH,
    SHOP_DELETE_PATH,
} from "../config/index";
import {LOGOUT} from './users'
import axios from "axios";

const init = {
    _get_all_shop: {
        data: [],
        status: 0,
        message: {},
    },
    _create_shop: {
        data: {},
        status: 0,
        message: {},
    },
    _update_shop: {
        data: {},
        status: 0,
        message: {},
    },
    _delete_shop: {
        data: {},
        status: 0,
        message: {},
    },
};

const GET_ALL_SHOP_SUCCESS = "GET_ALL_SHOP_SUCCESS";
export const CREATE_SHOP = "CREATE_SHOP";
export const UPDATE_SHOP = "UPDATE_SHOP";
export const DELETE_SHOP = "DELETE_SHOP";

export const shop_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_SHOP_SUCCESS:
            return {
                ...state,
                _get_all_shop: action.payload,
            };
        case CREATE_SHOP:
            return {
                ...state,
                _create_shop: action.payload,
            };
        case UPDATE_SHOP:
            return {
                ...state,
                _update_shop: action.payload,
            };
        case DELETE_SHOP:
            return {
                ...state,
                _delete_shop: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
};


export const get_all_shop = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${SHOP_PATH}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_SHOP_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: GET_ALL_SHOP_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.message,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_SHOP_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Ocurrió un error",
                },
            });
        }
    }
}

export const create_shop = (token, data) => async (dispatch) => {
    try {
        const response = await axios.post(
            `${URLAPI}${SHOP_CREATE_PATH}`,
            data,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );
        dispatch({
            type: CREATE_SHOP,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: CREATE_SHOP,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_SHOP,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const update_shop = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(
            `${URLAPI}${SHOP_UPDATE_PATH}${id}/`,
            data,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );
        dispatch({
            type: UPDATE_SHOP,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: UPDATE_SHOP,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_SHOP,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const delete_shop = (token, id) => async (dispatch) => {
    try {
        const response = await axios.delete(
            `${URLAPI}${SHOP_DELETE_PATH}${id}/`,
            {
                headers: {
                    Authorization: `Basic ${token}`,
                },
            }
        );
        dispatch({
            type: DELETE_SHOP,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: DELETE_SHOP,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: DELETE_SHOP,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const clear_shop = (type) => async (dispatch) => {
    dispatch({
        type: type,
        payload: {
            data: [],
            status: 0,
            message: {},
        },
    });
};
