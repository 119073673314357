import {createStore, combineReducers, compose, applyMiddleware} from "redux";
import {loadState} from "./state";
import thunk from "redux-thunk";
import {persistStore, persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {users_module} from "./users";
import {family_module} from "./families";
import {attribute_module} from "./attributes";
import {category_module} from "./categories";
import {gender_module} from "./genders";
import {manufacturer_module} from "./manufacturers";
import {city_module} from "./cities";
import {item_module} from "./items";
import {shopping_cart_module} from "./shopping_carts";
import {quotation_module} from "./quotation";
import {states_module} from "./states";
import {order_module} from "./order";
import {bank_account_module} from "./bank_accounts";
import {banner_image_module} from "./banner_images";
import { type_payment_module } from "./type_payment";
import { shop_module } from "./shop";

const rootReducer = combineReducers({
    users_module: users_module,
    family_module: family_module,
    attribute_module: attribute_module,
    category_module: category_module,
    gender_module: gender_module,
    manufacturer_module: manufacturer_module,
    city_module: city_module,
    item_module: item_module,
    shopping_cart_module: shopping_cart_module,
    quotation_module: quotation_module,
    states_module: states_module,
    order_module: order_module,
    bank_account_module: bank_account_module,
    banner_image_module: banner_image_module,
    type_payment_module: type_payment_module,
    shop_module: shop_module,
});

const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const generateStore = () => {
    const initialData = loadState();

    const store = createStore(
        persistedReducer,
        initialData,
        composeEnhancers(applyMiddleware(thunk))
    );

    const persistor = persistStore(store, ["auth"]);

    return {store, persistor};
};
