import React, {useEffect, useState} from "react";
import Button from "../../components/button";
import Input from "../../components/input";
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {create_user, clear_user_module, CREATE_USER, searchGroup} from "../../redux/users";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {USERNAME, PASSWORD} from '../../config/index';
import {Buffer} from 'buffer';
import Modal from "../../components/modal";
import LoadingSpinner from "../../components/loading-spinner";


const CreateAccount = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const token = Buffer.from(`${USERNAME}:${PASSWORD}`).toString('base64');
    const _create_user = useSelector((state) => state.users_module._create_user);
    const [isLoading, setIsLoading] = useState(false);

    const [modal, setModal] = useState(false);

    const [data, setData] = useState({
        identificacion: "",
        usuario: "",
        nombres: "",
        apellidos: "",
        correo: "",
        telefono: "",
        foto: "",
        id_grupo_permiso: 0,
        url_confirmacion: window.location.origin + "/activar-cuenta",
        cliente: true,
    });

    async function getGroup() {
        const params = '?nombre=CLIENTES';
        const response = await searchGroup(token, params);
        if (response && response.data && response.data.datos) {
            setData({
                ...data,
                id_grupo_permiso: response.data.datos[0].id
            })
        }
    }

    useEffect(() => {
        getGroup();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (_create_user.status === 201) {
            dispatch(clear_user_module(CREATE_USER));
            setIsLoading(false);
            setModal(true);
        } else if (_create_user.status !== 0) {
            dispatch(clear_user_module(CREATE_USER));
            toast.error(_create_user.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_create_user, dispatch, history]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (data.identificacion === "") {
            toast.error("Debe ingresar su identificación", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (data.nombres === "") {
            toast.error("Debe ingresar sus nombres", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (data.apellidos === "") {
            toast.error("Debe ingresar sus apellidos", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (data.correo === "") {
            toast.error("Debe ingresar su correo electrónico", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (data.telefono === "") {
            toast.error("Debe ingresar su teléfono", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }

        dispatch(create_user(token, data));
    };

    return (
        <>
            {isLoading && <LoadingSpinner/>}
            <div className="app-content content">
                <div className="content-overlay"/>
                <div className="content-wrapper">
                    <div className="content-body">
                        <section className="row flexbox-container">
                            <div><ToastContainer/></div>
                            <div className="col-xl-11 col-12" style={{
                                backgroundImage: "url(/base/app-assets/images/logo/fondo_chile.png)",
                                backgroundRepeat: 'no-repeat',
                                height: '62em',
                                overflowX: 'scroll'
                            }}>
                                <div className="row m-2">
                                    <div className="col-md-6 col-12 div_texto mt-md-5 mt-1">
                                        <img
                                            src={
                                                window.location.origin +
                                                "/base/app-assets/images/logo/logo_color.png"
                                            }
                                            alt="branding logo"
                                            className="img_logo"
                                        />
                                        <div className="mt-md-5 mt-2">
                                            <p className="texto_1 text_black type_text">¡Regístrate en el sitio!</p>
                                        </div>
                                        <div className="mt-md-5 mt-2">
                                            <p className="texto_2 text_black type_text">Ingresa tus datos para que
                                                puedas acceder a nuestros servicios.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mt-md-5 mt-1">
                                        <div className="card div_create">
                                            <div className="card-content">
                                                <div className="card-body">
                                                    <div className="text-center">
                                                        <p className="texto_24 text_white">¡Únete!</p>
                                                    </div>
                                                    <div className="mt-1">
                                                        <form onSubmit={handleSubmit}>
                                                            <Input
                                                                type="text"
                                                                name="identificacion"
                                                                id="identificacion"
                                                                value={data.identificacion}
                                                                onChange={(e) =>
                                                                    setData({
                                                                        ...data,
                                                                        identificacion: e.target.value,
                                                                        usuario: e.target.value
                                                                    })
                                                                }
                                                                label="Identificación"
                                                                color_label="texto1"
                                                            />
                                                            <Input
                                                                type="text"
                                                                name="nombres"
                                                                id="nombres"
                                                                value={data.nombres}
                                                                onChange={(e) =>
                                                                    setData({...data, nombres: e.target.value})
                                                                }
                                                                label="Nombres"
                                                                color_label="texto1"
                                                            />
                                                            <Input
                                                                type="text"
                                                                name="apellidos"
                                                                id="apellidos"
                                                                value={data.apellidos}
                                                                onChange={(e) =>
                                                                    setData({...data, apellidos: e.target.value})
                                                                }
                                                                label="Apellidos"
                                                                color_label="texto1"
                                                            />
                                                            <Input
                                                                type="text"
                                                                name="correo"
                                                                id="correo"
                                                                value={data.correo}
                                                                onChange={(e) =>
                                                                    setData({...data, correo: e.target.value})
                                                                }
                                                                label="Correo electrónico"
                                                                color_label="texto1"
                                                            />
                                                            <Input
                                                                type="text"
                                                                name="telefono"
                                                                id="telefono"
                                                                value={data.telefono}
                                                                onChange={(e) =>
                                                                    setData({...data, telefono: e.target.value})
                                                                }
                                                                label="Teléfono"
                                                                color_label="texto1"
                                                            />
                                                            <Button
                                                                type="submit"
                                                                classes="btn btn-calendar-cliente glow w-100 position-relative"
                                                                text="Registrarse"
                                                            />
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="form-group align-items-center">
                                                    <div className="text-center texto1">
                                                        <Link to="/" className="card-link texto1">
                                                            <small><u><strong>Regresar</strong></u></small>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Modal className="modal-main" show={modal} style={{width: "25%"}}>
                        <div className="card-body">
                            <h5 className="titulo_modal type_text mb-2">Datos ingresados</h5>
                            <p className="texto_modal text_black type_text text-center">Hemos recibido tus datos, por
                                favor revisa la bandeja de entrada de tu correo electrónico para que continúes con el
                                proceso de registro.</p>
                        </div>
                        <div className="card-footer d-flex justify-content-center">
                            <button onClick={() => {
                                setModal(false);
                                history.push("/");
                            }} className={`btn btn-calendar`}>Entendido
                            </button>
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default CreateAccount;
