import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useHistory, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import Footer from "../../../components/footer";
import Button from "../../../components/button";
import {
    search_items
} from "../../../redux/items";
import LoadingSpinner from "../../../components/loading-spinner";
import {URLAPI} from "../../../config";
import Input from "../../../components/input";
import SelectInput from "../../../components/select";

const Item = (props) => {
    const {nombre, id} = useParams();
    const history = useHistory();

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [showSibarLeft, setShowSibarLeft] = useState(false);

    const [permisos, setPermisos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [datas, setDatas] = useState([]);
    const [page, setPage] = useState(1);
    const [n_page, setNPage] = useState(0);

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);
            // eslint-disable-next-line
            getItems(nombre, 1);
        }
        // eslint-disable-next-line
    }, [props]);

    async function getItems(nombre, page) {
        setIsLoading(true);
        const params = '?familia=' + nombre + '&page=' + page;
        const response = await search_items(token, params);
        if (response.status === 200) {
            setDatas(response.data.datos);
            setNPage(response.data.n_page);
        }
        setIsLoading(false);
    }

    const [search, setSearch] = useState('');

    const handleSearch = () => {
        if (search !== "") {
            let values = datas.filter((x) => x.codigo_interno === search);
            setDatas(values);
        } else {
            // eslint-disable-next-line
            getItems();
            setRangeDate({
                date_end: '',
                date_start: '',
                visible: false,
            });
        }
    }

    const handleChange = (e) => {
        if (e.target.value === '') {
            // eslint-disable-next-line
            getItems();
        }
        setSearch(e.target.value)
    }

    const filter_options = [
        {name: 'Rango de fechas', id: 1}
    ];

    const filterToday = (e) => {
        if (e.target.value === '1') {
            setRangeDate({
                date_end: '',
                date_start: '',
                visible: true,
            });
        } else {
            setRangeDate({
                date_end: '',
                date_start: '',
                visible: false,
            });
            // eslint-disable-next-line
            getItems();
        }
    }

    const [range_date, setRangeDate] = useState({
        visible: false,
        date_start: '',
        date_end: ''
    })

    const handleFilterRangeDate = async (e) => {
        if (e.target.name === 'date_start') {
            range_date.date_start = e.target.value
        }
        if (e.target.name === 'date_end') {
            range_date.date_end = e.target.value
        }

        if (range_date.date_start !== '' && range_date.date_end !== '') {
            setIsLoading(true);
            const params = '?familia=' + nombre + '&&fecha_inicio=' + range_date.date_start + '&&fecha_fin=' + range_date.date_end;
            const response = await search_items(token, params);
            if (response.status === 200) {
                setDatas(response.data.datos);
            } else {
                setDatas([]);
            }
            setIsLoading(false);
        }
    }

    function more_data() {
        let count = page + 1;
        setPage(count)
        getItems(nombre, count);
    }


    function less_data() {
        let count = page - 1;
        setPage(count)
        getItems(nombre, count);
    }

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Catálogo"
                            items={[{label: "Lista", link: "/catalogo"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    {isLoading && <LoadingSpinner/>}
                    <div className="content-area-wrapper" style={{margin: 0}}>
                        <div className={`sidebar-left ${showSibarLeft ? 'show' : ''}`}>
                            <div className="sidebar">
                                <div className="sidebar-content email-app-sidebar d-flex">
                                    <span className="sidebar-close-icon" onClick={
                                        () => {
                                            setShowSibarLeft(false)
                                        }
                                    }>
                                        <i className="bx bx-x"/>
                                    </span>
                                    <div className="email-app-menu">
                                        <div className="form-group form-group-compose">
                                            {
                                                permisos.filter(element => element.codigo === "add_items").length > 0 ?
                                                    <Button
                                                        text="Crear"
                                                        onChange={() => {
                                                            history.push(`/catalogo/crear/item/${nombre}/${id}`);
                                                        }}
                                                        theme="calendar"
                                                        classes=" btn-block my-2 compose-btn"
                                                    />
                                                    : null
                                            }

                                        </div>
                                        <div className="sidebar-menu-list">
                                            <div className="list-group list-group-messages">
                                                <SelectInput
                                                    id="filtro_cotizacion"
                                                    name="filtro-cotizacion"
                                                    options={filter_options}
                                                    onChange={filterToday}
                                                />
                                                <ul className="list-unstyled mb-0">
                                                    {
                                                        range_date.visible ? <>
                                                            <li className=" mr-2 mb-1">
                                                                <Input
                                                                    type="date"
                                                                    label="Fecha Inicio"
                                                                    onChange={handleFilterRangeDate}
                                                                    name='date_start'

                                                                />
                                                                <Input
                                                                    type="date"
                                                                    label="Fecha Fin"
                                                                    onChange={handleFilterRangeDate}
                                                                    name='date_end'

                                                                />
                                                            </li>
                                                        </> : null
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-right">
                            <div className="content-overlay"/>
                            <div className="content-wrapper">
                                <div className="content-header row"/>
                                <div className="content-body">
                                    <div className="email-app-area">
                                        <div className="email-app-list-wrapper">
                                            <div className="email-app-list">
                                                <div className="email-action">
                                                    <div
                                                        className="action-right d-flex flex-grow-1 align-items-center justify-content-around">
                                                        <div className="email-fixed-search flex-grow-1">
                                                            <div className="row">
                                                                <div className="col-md-10 col-sm-8 col-12">
                                                                    <Input
                                                                        name="search"
                                                                        value={search}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                                <div className="col-md-2 col-sm-4 col-12 text-right">
                                                                    <Button text="Buscar" theme="calendar" type="button"
                                                                            classes="btn-lg"
                                                                            onClick={() => handleSearch()}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="email-user-list list-group"
                                                     style={{overflowY: 'scroll',}}>
                                                    <div className="card-body">
                                                        {(n_page > 1) &&
                                                            <div className="row"
                                                                 style={{marginTop: 10, marginBottom: 5}}>
                                                                <div className="col-4">
                                                                    <label className="ml-1">Página {page}</label>
                                                                </div>
                                                                <div className="col-8 text-right">
                                                                    {(page > 1 && page <= n_page) &&
                                                                        <button className="btn btn-calendar mr-1"
                                                                                type="button" onClick={() => {
                                                                            less_data();
                                                                        }}><i className="bx bx-arrow-back"></i></button>
                                                                    }
                                                                    {(page >= 1 && page < n_page) &&
                                                                        <button className="btn btn-calendar"
                                                                                type="button" onClick={() => {
                                                                            more_data();
                                                                        }}><i className="bx bx-right-arrow-alt"></i>
                                                                        </button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="row">
                                                            {datas && (
                                                                datas.map((x) => (
                                                                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                                                                        <div className="m-1 text-center border_card_1">
                                                                            {x.imagenes.length > 0 ? (
                                                                                <div className="m-1 text-center">
                                                                                    <img
                                                                                        src={URLAPI + x.imagenes[0].url}
                                                                                        width={'80%'}
                                                                                        height={200}
                                                                                        alt='Imagen'
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    className="m-1 text-center div_container_image">
                                                                                    <div className="div_image"/>
                                                                                </div>
                                                                            )}
                                                                            <p className="type_text text_black text_inter text_16 mb-0">{x.titulo}</p>
                                                                            <p className="type_text text_black text_inter text_16 mb-0">{x.codigo_interno}</p>
                                                                            <p className="type_text text_color text_inter text_16 mb-2">{x.activo ? 'Activo' : 'Inactivo'}</p>
                                                                            <button className="btn btn-calendar mb-1"
                                                                                    onClick={() => {
                                                                                        history.push(`/catalogo/editar/item/${nombre}/${x.id}`);
                                                                                    }}>Editar
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Item;
