import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../../components/footer";
import {ReactComponent as ReactCancel} from '../../../cancel.svg';
import Modal from "../../../components/modal";
import LoadingSpinner from "../../../components/loading-spinner";
import {URLAPI} from "../../../config";
import {clear_status_order, get_all_order_admin, update_order, UPDATE_ORDER_SUCCESS} from "../../../redux/order";

const ItemOrder = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {id} = useParams();

    const _get_all_order_admin = useSelector((state) => state.order_module._get_all_order_admin);
    const _update_order = useSelector((state) => state.order_module._update_order);
    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({
        fecha_creacion: "",
        codigo: "",
        estado: "",
        usuario: {
            id: 0,
            identificacion: "",
            nombres: "",
            apellidos: "",
            correo: "",
            telefono: ""
        },
        cotizacion: {detalles: []}
    });

    const [config, setConfig] = useState({
        moneda: '',
        cantidad_decimal: '',
        separador_decimal: '',
    });

    const [form, setForm] = useState({
        subtotal: 0,
        costo: 0,
        total: 0,
        anticipo: 0,
        pendiente: 0,
    });

    useEffect(() => {
        if (_get_all_order_admin.data && _get_all_order_admin.data.datos) {
            const value = _get_all_order_admin.data.datos.filter(
                (x) => x.id === parseInt(id)
            );
            if (value.length > 0) {
                setData(value[0]);
                let subtotal = 0;
                // eslint-disable-next-line
                value[0].cotizacion.detalles.map(a => {
                    subtotal += parseFloat(a.subtotal);
                });
                const total = parseFloat(subtotal) + parseFloat(value[0].cotizacion.costo);
                const saldo = parseFloat(total) - parseFloat(value[0].cotizacion.anticipo);
                setForm({
                    subtotal: subtotal.toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`),
                    costo: parseFloat(value[0].cotizacion.costo).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`),
                    total: total.toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`),
                    anticipo: parseFloat(value[0].cotizacion.anticipo).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`),
                    pendiente: saldo.toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)
                })
            }
            setConfig(_get_all_order_admin.data.configuraciones);
        }
    }, [_get_all_order_admin, id, config]);

    const [modalConfirmPayment, setModalConfirmPayment] = useState(false);
    const [modalCancelPayment, setModalCancelPayment] = useState(false);

    useEffect(() => {
        if (_update_order.status === 200) {
            dispatch(get_all_order_admin(token, 1));
            setIsLoading(false);
            setModalConfirmPayment(false);
            setModalCancelPayment(false);
            dispatch(clear_status_order(UPDATE_ORDER_SUCCESS));
            history.push("/fabricaciones");
        } else if (_update_order.status !== 0) {
            setIsLoading(false);
            dispatch(clear_status_order(UPDATE_ORDER_SUCCESS));
            toast.error(_update_order.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_update_order, dispatch, history, token]);

    return (
        <>
            {isLoading && <LoadingSpinner/>}
            <div className="app-content content">
                <div className="content-overlay"/>
                <div className="content-wrapper">
                    <div className="content-header row">
                        <div className="content-header-left col-12 mb-2 mt-3">
                            <Breadcrumbs
                                title="Fabricaciones"
                                items={[
                                    {label: `${data.codigo}`, link: `/fabricaciones`},
                                ]}
                            />
                        </div>
                    </div>
                    <div className="content-body">
                        <div><ToastContainer/></div>
                        <div className="card pl-5 pr-5">
                            <div className="card-body">
                                {(data && data.codigo !== '') &&
                                    <>
                                        <div className="ml-1">
                                            <div className="d-flex">
                                                <div style={{marginTop: 5, cursor: 'pointer'}} onClick={() => {
                                                    history.push(`/fabricaciones`);
                                                }}>
                                                    <ReactCancel width={32} heght={29}/>
                                                </div>
                                                <p className="ml-2 type_text text_color text_inter text_32">{data.codigo !== '' && data.codigo}</p>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-6 col-sm-12">
                                                <p className="type_text text_black text_inter text_16">Cliente: {data.cotizacion.usuario.nombres + ' ' + data.cotizacion.usuario.apellidos}</p>
                                                <p className="type_text text_black text_inter text_16">Ciudad: {data.cotizacion.ciudad}</p>
                                                <p className="type_text text_black text_inter text_16">Dirección: {data.cotizacion.direccion}</p>
                                            </div>
                                            <div className="col-md-6 col-sm-12 text-right">
                                                <p className="type_text text_black text_inter text_16">Fecha de
                                                    creación: {data.fecha_creacion}</p>
                                                <p className="type_text text_black text_inter text_16">Estado: {data.estado}</p>
                                            </div>
                                        </div>
                                        {(data.cotizacion.detalles.length > 0) && (
                                            data.cotizacion.detalles.map((x) => (
                                                <div className="border_card_1 p-1 mb-1">
                                                    <div className="row">
                                                        <div className="col-md-6 col-sm-12">
                                                            {(x.imagenes.length > 0) &&
                                                                <img
                                                                    src={URLAPI + x.imagenes[0].url}
                                                                    width={'60%'}
                                                                    height={380}
                                                                    alt='Imagen'
                                                                />
                                                            }
                                                        </div>
                                                        <div className="col-md-6 col-sm-12">
                                                            <p className="type_text text_32 text_color">{x.titulo}</p>
                                                            <p className="type_text text_16 text_black">{x.descripcion}</p>
                                                            <p className="type_text text_16 text_black mb-2">Código: {x.codigo_interno}</p>
                                                            {(x.valores) && x.valores.map((y) => (
                                                                <div className="mt-1">
                                                                    <p className="type_text text_14 text_black mb-0">{y.atributo}</p>
                                                                    <p className="type_text text_12 text_black div_detail"
                                                                       style={{padding: '7px 10px'}}>{y.valor}</p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className="col-md-4 col-sm-12">
                                                            <p className="type_text text_14 text_black mb-0">Cantidad</p>
                                                            <p className="type_text text_12 text_black div_detail"
                                                               style={{padding: '7px 10px'}}>{x.cantidad}</p>
                                                        </div>
                                                        <div className="col-md-4 col-sm-12">
                                                            <p className="type_text text_14 text_black mb-0">Precio
                                                                unitario</p>
                                                            <p className="type_text text_12 text_black div_detail"
                                                               style={{padding: '7px 10px'}}>{config.moneda} {x.precio1}</p>
                                                        </div>
                                                        <div className="col-md-4 col-sm-12">
                                                            <p className="type_text text_14 text_black mb-0">Subtotal</p>
                                                            <p className="type_text text_12 text_black div_detail"
                                                               style={{padding: '7px 10px'}}>{config.moneda} {x.subtotal1}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        )}
                                        <div className="border_card_1 p-1 mt-1">
                                            <div className="row">
                                                <div className="col-md-4 col-sm-12">
                                                    <p className="ml-2 type_text text_color text_inter text_32 mt-2">Resumen</p>
                                                </div>
                                                <div className="col-md-4 col-sm-12">
                                                    <p className="type_text text_14 text_black mb-0">Subtotal
                                                        fabricación</p>
                                                    <p className="type_text text_14 text_black mb-0">Costo de envío</p>
                                                    <p className="type_text text_14 text_black mb-0">Total de
                                                        fabricación</p>
                                                    {/* eslint-disable-next-line */}
                                                    <p className="type_text text_14 text_black mb-0">Anticipo {(data.cotizacion.archivo_anticipo) && (
                                                        <a href={URLAPI + data.cotizacion.archivo_anticipo}
                                                           target="_blank" style={{textDecoration: 'underline'}}>Ver
                                                            comprobante</a>)}</p>
                                                    {/* eslint-disable-next-line */}
                                                    <p className="type_text text_14 text_black mb-0">Saldo
                                                        pendiente {(data.archivo_final) && (
                                                            <a href={URLAPI + data.archivo_final} target="_blank"
                                                               style={{textDecoration: 'underline'}}>Ver
                                                                comprobante</a>)}</p>
                                                </div>
                                                <div className="col-md-4 col-sm-12">
                                                    <p className="text-right type_text text_14 text_black mb-0">{config.moneda} {form.subtotal}</p>
                                                    <p className="text-right type_text text_14 text_black mb-0">{config.moneda} {form.costo}</p>
                                                    <p className="text-right type_text text_14 text_black mb-0">{config.moneda} {form.total}</p>
                                                    <p className="text-right type_text text_14 text_black mb-0">{config.moneda} {form.anticipo}</p>
                                                    <p className="text-right type_text text_14 text_black mb-0">{config.moneda} {form.pendiente}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="card-footer d-flex justify-content-end">
                                {data.estado === 'Pendiente inicio' &&
                                    <button className="btn btn-calendar" onClick={() => {
                                        setIsLoading(true);
                                        dispatch(update_order(token, id, {estado: 'INICIAR'}));
                                    }}>Iniciar fabricación</button>
                                }
                                {data.estado === 'En proceso' &&
                                    <button className="btn btn-calendar" onClick={() => {
                                        setIsLoading(true);
                                        dispatch(update_order(token, id, {estado: 'FINALIZAR'}));
                                    }}>Terminar fabricación</button>
                                }
                                {data.estado === 'Revisión pago' &&
                                    <>
                                        <button type="button" className="btn btn-outline-calendar mr-1" onClick={() => {
                                            setModalCancelPayment(true);
                                        }}>Notificar pago incompleto
                                        </button>
                                        <button type="button" className="btn btn-calendar" onClick={() => {
                                            setModalConfirmPayment(true);
                                        }}>Aprobar pago
                                        </button>
                                    </>
                                }
                                {data.estado === 'Finalizada' &&
                                    <button className="btn-outline-calendar" onClick={() => {
                                        history.push("/fabricaciones");
                                    }}>Atrás</button>
                                }
                            </div>
                        </div>
                    </div>
                    <Modal className="modal-main div-modal" show={modalConfirmPayment}>
                        {isLoading && <LoadingSpinner/>}
                        <div className="card-body">
                            <h5 className="titulo_modal type_text mb-2">Aprobar pago</h5>
                            <div className="text-center">
                                <p className="type_text text_16 text_black">Se notificará al cliente que el pago final
                                    ha sido aprobado y se realizará el envío de sus joyas.</p>
                            </div>
                        </div>
                        <div className="card-footer text-center">
                            <button type="button" onClick={() => {
                                setIsLoading(true);
                                dispatch(update_order(token, id, {estado: 'APROBADO'}));
                            }} className={`btn btn-calendar mb-1 button_div`}>Confirmar
                            </button>
                            <button type="button" onClick={() => {
                                setModalConfirmPayment(false);
                            }} className={`btn btn-outline-calendar button_div`}>Cancelar
                            </button>
                        </div>
                    </Modal>
                    <Modal className="modal-main div-modal" show={modalCancelPayment}>
                        {isLoading && <LoadingSpinner/>}
                        <div className="card-body">
                            <h5 className="titulo_modal type_text mb-2">Notificar pago incompleto</h5>
                            <div className="text-center">
                                <p className="type_text text_16 text_black">Se notificará al cliente que su pago no fue
                                    aprobado y debe volver a subir el comprobante.</p>
                            </div>
                        </div>
                        <div className="card-footer text-center">
                            <button type="button" onClick={() => {
                                setIsLoading(true);
                                dispatch(update_order(token, id, {estado: 'CANCELADO'}));
                            }} className={`btn btn-calendar mb-1 button_div`}>Confirmar
                            </button>
                            <button type="button" onClick={() => {
                                setModalCancelPayment(false);
                            }} className={`btn btn-outline-calendar button_div`}>Cancelar
                            </button>
                        </div>
                    </Modal>
                </div>
                <Footer/>
            </div>
        </>
    );
};

export default ItemOrder;
