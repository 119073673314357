import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import Footer from "../../../components/footer";

const Users = (props) => {
    let history = useHistory();
    const users = useSelector((state) => state.users_module._get_all_users);

    const [data, setData] = useState([]);

    useEffect(() => {
        if (users.data) {
            let values = users.data.filter(x => x.cliente === false);
            setData(values);
        }
    }, [users]);


    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);


    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);
        }
    }, [props]);


    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_user').length > 0) {
                act.push({
                    name: "edit",
                    label: "Editar",
                    icon: "bx bx-edit",
                    color: "calendar",
                    onClick: (item) => {
                        history.push(`/usuarios/editar/${item.id}`);
                    },
                })
            }
        }
        setActions(act);
    }, [permisos, history]);

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Usuarios"
                            items={[{label: "Lista", link: "/usuarios"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                {
                                    permisos.filter(x => x.codigo === 'add_user').length > 0 ?
                                        <Link
                                            to="/usuarios/crear"
                                            className="btn btn-calendar"
                                        >
                                            Crear
                                        </Link> : null
                                }

                                <div className="col-12 mb-2">
                                    <div className="table-responsive">

                                        <DataTable
                                            dataTable={data}
                                            columns={[
                                                {
                                                    name: "usuario",
                                                    label: "Usuario",
                                                    type: "text",
                                                },
                                                {
                                                    name: "nombres",
                                                    label: "Nombres",
                                                    type: "text",
                                                },
                                                {
                                                    name: "apellidos",
                                                    label: "Apellidos",
                                                    type: "text",
                                                },
                                                {
                                                    name: "correo",
                                                    label: "Correo",
                                                    type: "text",
                                                },
                                                {
                                                    name: "activo",
                                                    label: "Activo",
                                                    type: "boolean",
                                                }
                                            ]}
                                            actions={actions}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Users;
