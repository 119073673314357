import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import LoadingSpinner from "../../../components/loading-spinner";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {URLAPI} from "../../../config";
import {
    delete_item_shopping_cart,
    DELETE_ITEM_SHOPPING_CART_SUCCESS,
    clear_status_shopping_cart,
    get_all_shopping_cart,
    update_shopping_cart,
    UPDATE_SHOPPING_CART_SUCCESS
} from "../../../redux/shopping_carts";
import Modal from "../../../components/modal";
import Input from "../../../components/input";
import SelectInput from "../../../components/select";
import {search_values_attributes} from "../../../redux/items";
import FooterClient from "../../../components/footer_client";

const Cart = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const [isLoading, setIsLoading] = useState(false);

    const [datas, setDatas] = useState([]);

    const _get_all_shopping_cart = useSelector((state) => state.shopping_cart_module._get_all_shopping_cart);
    const _delete_item_shopping_cart = useSelector((state) => state.shopping_cart_module._delete_item_shopping_cart);
    const _update_shopping_cart = useSelector((state) => state.shopping_cart_module._update_shopping_cart);


    useEffect(() => {
        setIsLoading(true);
        if (_get_all_shopping_cart.data && _get_all_shopping_cart.data.datos) {
            setDatas(_get_all_shopping_cart.data.datos);
            setIsLoading(false);
        }
    }, [_get_all_shopping_cart, isLoading]);

    useEffect(() => {
        if (_delete_item_shopping_cart.status === 200) {
            dispatch(clear_status_shopping_cart(DELETE_ITEM_SHOPPING_CART_SUCCESS));
            history.push(`/carrito-compra`);
            dispatch(get_all_shopping_cart(token));
        } else if (_delete_item_shopping_cart.status !== 0) {
            dispatch(clear_status_shopping_cart(DELETE_ITEM_SHOPPING_CART_SUCCESS));
            toast.error(_delete_item_shopping_cart.message, {position: toast.POSITION.TOP_RIGHT});
        }
        setIsLoading(false);
    }, [_delete_item_shopping_cart, dispatch, history, token]);

    useEffect(() => {
        if (_update_shopping_cart.status === 200) {
            dispatch(clear_status_shopping_cart(UPDATE_SHOPPING_CART_SUCCESS));
            history.push(`/carrito-compra`);
            dispatch(get_all_shopping_cart(token));
            setModalDetail(false);
        } else if (_update_shopping_cart.status !== 0) {
            dispatch(clear_status_shopping_cart(UPDATE_SHOPPING_CART_SUCCESS));
            toast.error(_update_shopping_cart.message, {position: toast.POSITION.TOP_RIGHT});
        }
        setIsLoading(false);
    }, [_update_shopping_cart, dispatch, history, token]);

    function deleteitem(id) {
        dispatch(delete_item_shopping_cart(token, id));
    }

    const [form, setForm] = useState({
        id: 0,
        titulo: '',
        id_item: 0,
        cantidad: 0,
        valores_atributos: [],
    });

    const [modalDetail, setModalDetail] = useState(false);

    function edit_item(id) {
        setModalDetail(true);
        let value = datas.filter((x) => x.id === id);
        if (value.length > 0) {
            setForm({
                ...form,
                id: id,
                titulo: value[0].titulo,
                id_item: value[0].id_item,
                cantidad: value[0].cantidad,
                valores_atributos: value[0].valores
            })
        }
    }

    async function getAttributeValue(id_attribute_value, id_attribute_value_fiter, atributo, y) {
        setIsLoading(true);
        const params = '?id_valor_atributo=' + id_attribute_value + '&&atributo=' + atributo + '&&id_item=' + form.id_item + '&&id_valor_atributo_filtro=' + id_attribute_value_fiter;
        const response = await search_values_attributes(token, params);
        if (response.status === 200) {
            if (response.data.datos.length > 0) {
                let va = [];
                // eslint-disable-next-line
                form.valores_atributos.map((m, n) => {
                    if (n < y) {
                        va.push(m);
                    }
                    if (n === y) {
                        let filter = form.valores_atributos[y].valores.filter(a => a.id === id_attribute_value_fiter);
                        va.push({
                            'id': id_attribute_value_fiter,
                            'atributo': m.atributo,
                            'valor': filter[0].nombre,
                            'valores': m.valores
                        })
                    }

                    if (n === (y + 1)) {
                        va.push({'id': 0, 'atributo': m.atributo, 'valor': '', 'valores': response.data.datos});
                    }
                    if (n > (y + 1)) {
                        va.push({'id': 0, 'atributo': m.atributo, 'valor': '', 'valores': []});
                    }
                })
                setForm({
                    ...form,
                    valores_atributos: va
                });
            }
        }
        setIsLoading(false);
    }

    const handleChangeQty = (e) => {
        setForm({
            ...form,
            cantidad: e.target.value
        })
    }

    const handleChange = (e, y) => {
        // eslint-disable-next-line
        form.valores_atributos.map((a, b) => {
            if (b > y) {
                document.getElementById(a.atributo).selectedIndex = 0;
                form.valores_atributos[b].valores = []
            }
        })
        if ((y + 1) < form.valores_atributos.length) {
            const id_attribute_value = (y === 0) ? parseInt(e.target.value) : form.valores_atributos[0].id;
            getAttributeValue(id_attribute_value, parseInt(e.target.value), form.valores_atributos[y + 1].atributo, y)

        } else {
            let filter = form.valores_atributos[y].valores.filter(a => a.id === parseInt(e.target.value))
            form.valores_atributos[y].id = parseInt(e.target.value);
            form.valores_atributos[y].valor = filter[0].nombre;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (parseFloat(form.cantidad) <= 0) {
            toast.error("La cantidad debe ser mayor a 0", {position: toast.POSITION.TOP_RIGHT});
            return
        }

        let valid = true;
        // eslint-disable-next-line
        form.valores_atributos.map((x) => {
            if (x.id === 0) {
                toast.error("Debe seleccionar una opción para " + x.atributo, {position: toast.POSITION.TOP_RIGHT});
                valid = false;
                // eslint-disable-next-line
                return;
            }
        })
        if (valid === true) {
            let values = [];
            // eslint-disable-next-line
            form.valores_atributos.map((item) => {
                values.push(item.id)
            })
            const data = new FormData();
            data.append('id_item', form.id_item);
            data.append('cantidad', form.cantidad);
            data.append('valores', JSON.stringify(values));
            dispatch(update_shopping_cart(token, form.id, data));
        }
    };

    return (
        <div className="color box_shadow">
            <div className="p-1">
                <div className="card-body">
                    {isLoading && <LoadingSpinner/>}
                    <div><ToastContainer/></div>
                    <p className="type_text text_20 text_black">Carrito</p>
                    <p className="type_text text_16 text_black">A continuación te mostramos los productos agregados a tu
                        carrito</p>
                    <div className="mt-2">
                        {
                            datas.map((item) => (
                                <div className="row border_card_1 mb-1 p-1">
                                    <div className="col-md-2 col-sm-6 col-12 mb-1 text-center">
                                        <img
                                            src={URLAPI + item.imagenes[0].url}
                                            width={120}
                                            height={120}
                                            alt='Imagen'
                                        />
                                    </div>
                                    <div className="col-md-10 col-sm-6 col-12">
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <p className="type_text text_24 text_color">{item.titulo}</p>
                                                <div className="div-item">
                                                    {item.valores.map((y) => (
                                                        <p className="type_text text_16 text_color mt-0 mr-2">{y.atributo}: {y.valor}</p>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="col-md-2 col-sm-4 col-12">
                                                <p className="type_text text_24 text_color mt-0">Código</p>
                                                <p className="type_text text_16 text_color mt-0">{item.codigo_interno}</p>
                                            </div>
                                            <div className="col-md-2 col-sm-4 col-12">
                                                <p className="type_text text_24 text_color mt-0">Cantidad</p>
                                                <p className="type_text text_16 text_color mt-0">{item.cantidad}</p>
                                            </div>
                                            <div className="col-md-2 col-sm-4 col-12 text-center div-item">
                                                {/* eslint-disable-next-line */}
                                                <a href="#" onClick={() => {
                                                    edit_item(item.id);
                                                }}>
                                                    <img
                                                        style={{marginTop: 28, marginRight: 10}}
                                                        className="logo"
                                                        src="../../../base/app-assets/images/icon/edit.png"
                                                        alt="logo"
                                                        width={40}
                                                        height={40}
                                                    />
                                                </a>
                                                {/* eslint-disable-next-line */}
                                                <a href="#" onClick={() => {
                                                    deleteitem(item.id);
                                                }}>
                                                    <img
                                                        className="logo mt-2"
                                                        src="../../../base/app-assets/images/icon/delete.png"
                                                        alt="logo"
                                                        width={50}
                                                        height={50}
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </div>
                <div className="card-footer text-right">
                    <button type="button" onClick={() => {
                        history.push(`/`);
                        document.getElementById("catalogo").className = "type_text text_black text_16 active";
                        document.getElementById("cotizaciones").className = "type_text text_black text_16";
                        document.getElementById("carrito").className = "type_text text_black text_16";
                    }} className={`btn btn-outline-calendar-cliente1`} style={{margin: 1}}>Seguir cotizando
                    </button>
                    {datas.length > 0 &&
                        <button type="button" onClick={() => {
                            history.push(`/carrito-compra-contacto`);
                        }} className={`btn btn-calendar-cliente1`} style={{margin: 1}}>Continuar</button>
                    }
                </div>
            </div>
            <Modal className="modal-main div-modal" show={modalDetail}>
                {isLoading && <LoadingSpinner/>}
                <form onSubmit={handleSubmit}>
                    <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">{form.titulo}</h5>
                        <div className="form-group">
                            <Input
                                type="number"
                                id="Cantidad"
                                label="Cantidad"
                                name="Cantidad"
                                value={form.cantidad}
                                onChange={handleChangeQty}
                            />
                        </div>
                        {(form.valores_atributos.length > 0) && (
                            form.valores_atributos.map((x, y) => (
                                <div className="form-group">
                                    <small className="texto2">{x.atributo}</small>
                                    <SelectInput
                                        id={x.atributo}
                                        label={x.atributo}
                                        name={x.atributo}
                                        select={x.valor}
                                        onChange={(e) => handleChange(e, y)}
                                        options={(x.valores) ? x.valores.map((item) => ({
                                            name: item.nombre,
                                            id: item.id,
                                        })) : []}
                                    />
                                </div>
                            ))
                        )}
                    </div>
                    <div className="card-footer text-center">
                        <button type="submit" className={`btn btn-calendar-cliente1 mb-1 button_div`}>Actualizar
                            detalle
                        </button>
                        <button type="button" onClick={() => {
                            setModalDetail(false);
                        }} className={`btn btn-outline-calendar-cliente1 button_div`}>Cancelar
                        </button>
                    </div>
                </form>
            </Modal>
            <FooterClient/>
        </div>
    );
};

export default Cart;
