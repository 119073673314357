import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import LoadingSpinner from "../../../components/loading-spinner";
import 'react-toastify/dist/ReactToastify.css';
import {URLAPI, ORDER_DOWNLOAD_PDF_PATH} from "../../../config";
import {ReactComponent as ReactCancel} from '../../../cancel.svg';
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Modal from "../../../components/modal";
import Input from "../../../components/input";
import {get_all_order, clear_status_order, payment_order, PAYMENT_ORDER_SUCCESS} from "../../../redux/order";

const ItemOrder = (props) => {
    const history = useHistory();
    const {id} = useParams();
    const dispatch = useDispatch();
    const _payment_order = useSelector((state) => state.order_module._payment_order);
    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const [isLoading, setIsLoading] = useState(false);

    const [data, setData] = useState({
        fecha_creacion: "",
        codigo_interno: "",
        estado: "",
        cotizacion: {
            detalles: [],
            valor_anticipo: 0,
            costo: 0,
            total: 0,
        }
    });

    const [config, setConfig] = useState({
        moneda: '',
        cantidad_decimal: '',
        separador_decimal: '',
    });

    const _get_all_order = useSelector((state) => state.order_module._get_all_order);
    //const _delete_item_shopping_cart = useSelector((state) => state.shopping_cart_module._delete_item_shopping_cart);


    useEffect(() => {
        if (_get_all_order.data && _get_all_order.data.datos) {
            let value = _get_all_order.data.datos.filter(x => parseInt(x.id) === parseInt(id));
            if (value.length > 0) {
                setData(value[0]);
            }
            setConfig(_get_all_order.data.configuraciones);
        }
    }, [_get_all_order, id]);

    const [modalPayment, setModalPayment] = useState(false);
    const [file, setFile] = useState('');

    useEffect(() => {
        if (_payment_order.status === 200) {
            dispatch(clear_status_order(PAYMENT_ORDER_SUCCESS));
            setIsLoading(false);
            dispatch(get_all_order(token, 1));
            setModalPayment(false);
            history.push(`/mis-fabricaciones`);
        } else if (_payment_order.status !== 0) {
            dispatch(clear_status_order(PAYMENT_ORDER_SUCCESS));
            toast.error(_payment_order.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_payment_order, dispatch, token, history, id]);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate fields

        if (file === "") {
            toast.error("Debe seleccionar un comprobante", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        let datas = new FormData();
        datas.append(`comprobante`, file);
        setIsLoading(true);
        dispatch(payment_order(token, id, datas))

    }

    return (
        <div className="color box_shadow">
            <div className="p-1">
                <div className="card-body">
                    {isLoading && <LoadingSpinner/>}
                    <div><ToastContainer/></div>
                    {data && (
                        <>
                            <div className="d-flex">
                                <div style={{marginRight: 10, cursor: 'pointer'}} onClick={() => {
                                    history.push(`/mis-fabricaciones`);
                                }}>
                                    <ReactCancel width={32} heght={29}/>
                                </div>
                                <p className="type_text text_24 text_black"
                                   style={{marginTop: 2, fontWeight: 'bold'}}>{data.codigo}</p>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p className="type_text text_16 text_black">Fecha de
                                        creación: {data.fecha_creacion}</p>
                                    <p className="type_text text_16 text_black">Estado: {data.estado}</p>
                                </div>

                            </div>
                        </>
                    )}

                    {(data.estado === 'Pendiente inicio') && (
                        <div className="mt-2">
                            <div className="border_card_2 p-1">
                                <p className="type_text text_16 text_white mt-1">Vamos a iniciar el proceso de
                                    fabricación de tus joyas, te informaremos por correo electrónico cuando estén listas
                                    para retirar.</p>
                            </div>
                        </div>
                    )}
                    {(data.estado === 'En proceso') && (
                        <div className="mt-2">
                            <div className="border_card_2 p-1">
                                <p className="type_text text_16 text_white mt-1">Estamos en proceso de fabricación de
                                    tus joyas, te informaremos por correo electrónico cuando estén listas para
                                    retirar.</p>
                            </div>
                        </div>
                    )}
                    {(data.estado === 'Fabricado') && (
                        <div className="mt-2">
                            <div className="border_card_2 p-1">
                                <p className="type_text text_16 text_white mt-1">Hemos terminado la fabricación de tus
                                    joyas, por favor debes realizar el pago del saldo pendiente y cargar el comprobante
                                    en esta página.</p>
                            </div>
                        </div>
                    )}
                    {(data.estado === 'Finalizada') && (
                        <div className="mt-2">
                            <div className="border_card_2 p-1">
                                <p className="type_text text_16 text_white mt-1">Hemos validado tu pago, tu fabricación
                                    está lista para el envío.</p>
                            </div>
                        </div>
                    )}

                    <div className="mt-2">
                        {(data && data.cotizacion.detalles.length > 0) && (
                            data.cotizacion.detalles.map((x) => (
                                <div className="col-12 mt-2">
                                    <div className="border_card_1 p-1">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-12 col-sm-12 text-center">
                                                {(x.imagenes.length > 0) &&
                                                    <img
                                                        src={URLAPI + x.imagenes[0].url}
                                                        className="img-item"
                                                        alt='Imagen'
                                                    />
                                                }
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <p className="type_text text_32 text_color">{x.titulo}</p>
                                                <p className="type_text text_16 text_black">{x.descripcion}</p>
                                                <p className="type_text text_16 text_black">Código: {x.codigo_interno}</p>
                                                {(x.valores) && x.valores.map((y) => (
                                                    <div className="mt-1 pr-md-5 pr-0">
                                                        <p className="type_text text_16 text_black mb-0">{y.atributo}</p>
                                                        <p className="type_text text_12 text_black div_detail"
                                                           style={{padding: '7px 10px'}}>{y.valor}</p>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <p className="type_text text_32 text_color">Valor cotizado</p>
                                                <table width={'98%'}>
                                                    <tr>
                                                        <th className="type_text text_20 text_black"
                                                            width={'50%'}>Cantidad
                                                        </th>
                                                        <th className="type_text text_20 text_black text-right"
                                                            width={'50%'}>{x.cantidad}</th>
                                                    </tr>
                                                    <tr>
                                                        <th className="type_text text_20 text_black pt-1">Precio</th>
                                                        <th className="type_text text_20 text_black pt-1 text-right">{config.moneda} {parseFloat(x.precio).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</th>
                                                    </tr>
                                                    <tr>
                                                        <th className="type_text texto text_black pt-3">Subtotal</th>
                                                        <th className="type_text texto text_black pt-3 text-right">{config.moneda} {parseFloat(x.subtotal).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</th>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                        {(data.cotizacion) && (
                            <div className="col-12 mt-2">
                                <table width={'98%'}>
                                    <tr>
                                        <th className="th-width"></th>
                                        <th className="type_text text_20 text_black mb-1 td-width">Anticipo de
                                            fabricacion
                                        </th>
                                        <th className="type_text text_20 text_black mb-1 text-right pr-md-3 pr-1">{config.moneda} {parseFloat(data.cotizacion.valor_anticipo).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</th>
                                    </tr>
                                    <tr>
                                        <th className="th-width"></th>
                                        <td colSpan={2} className="type_text text_20 text_black mb-1 td-width"><small>Corresponde
                                            al {config.porcentaje_anticipo}% del total de la fabricación, depósito o
                                            transferencia al confirmar la fabricación</small></td>
                                    </tr>
                                    <tr>
                                        <th className="th-width"></th>
                                        <th className="type_text text_20 text_black mb-1 td-width">Envio</th>
                                        <th className="type_text text_20 text_black mb-1 text-right pr-md-3 pr-1">{config.moneda} {parseFloat(data.cotizacion.costo).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th className="type_text texto text_black pt-1 mb-1">Total</th>
                                        <th className="type_text texto text_black pt-1 mb-1 text-right pr-md-3 pr-1">{config.moneda} {parseFloat(data.cotizacion.total).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</th>
                                    </tr>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
                <div className="card-footer text-right">
                    {(data && data.cotizacion.detalles.length > 0 && data.estado !== 'Fabricado') ? (
                        // eslint-disable-next-line
                        <a href={`${URLAPI}${ORDER_DOWNLOAD_PDF_PATH}${id}/`} target="_blank"
                           className="btn btn-calendar-cliente1">Descargar orden de fabricación</a>
                    ) : (
                        <>
                            {/* eslint-disable-next-line */}
                            <a href={`${URLAPI}${ORDER_DOWNLOAD_PDF_PATH}${id}/`} target="_blank"
                               className="btn btn-outline-calendar-cliente1" style={{margin: 1}}>Descargar orden de
                                fabricación</a>
                            <button className="btn btn-calendar-cliente1" onClick={() => {
                                setModalPayment(true);
                            }} style={{margin: 1}}>Cargar comprobante
                            </button>
                        </>
                    )}
                </div>
            </div>
            <Modal className="modal-main div-modal" show={modalPayment}>
                {isLoading && <LoadingSpinner/>}
                <form className="card" onSubmit={handleSubmit}>
                    <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">Cargar comprobante</h5>
                        <Input
                            name="file"
                            label="Archivo"
                            type="file"
                            onChange={handleFileChange}
                        />
                    </div>
                    <div className="card-footer text-center">
                        <button type="submit" className={`btn btn-calendar-cliente1 mb-1 button_div`}>Cargar</button>
                        <button type="button" onClick={() => {
                            setModalPayment(false);
                        }} className={`btn btn-outline-calendar-cliente1 button_div`}>Cancelar
                        </button>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default ItemOrder;
