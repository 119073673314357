import Users from "./admin/users/index";
import Clients from "./admin/users/clients";
import CreateUser from "./admin/users/create";
import UpdateUser from "./admin/users/update";
import GroupList from './admin/groups/index';
import CreateGroup from './admin/groups/create';
import UpdateGroup from './admin/groups/update';

import FamiliesList from './admin/families/index';
import FamilyCreate from './admin/families/create';
import FamilyUpdate from './admin/families/update';

import AttributesList from './admin/attributes/index';
import AttributeCreate from './admin/attributes/create';
import AttributeUpdate from './admin/attributes/update';

import CategoriesList from './admin/categories/index';
import CategoryCreate from './admin/categories/create';
import CategoryUpdate from './admin/categories/update';

import GendersList from './admin/genders/index';

import ManufacturersList from './admin/manufacturers/index';
import BankAccountsList from './admin/bank_accounts/index';

import QuotationsList from './admin/quotations/index';
import UpdateQuotation from './admin/quotations/update_quotation';

import CitiesList from './admin/cities/index';

import TypePaymentList from './admin/type_payment/index';

import ShopList from './admin/shops/index';

import CataloguesList from './admin/catalogues/index';
import ItemCatalogue from './admin/catalogues/item';
import CreateItem from './admin/catalogues/create_item';
import UpdateItem from './admin/catalogues/update_item';

import Home from "./admin/home/index";

import OrdersList from './admin/orders/index';
import ItemOrder from './admin/orders/item_order';

import BannerImagesList from './admin/banner_images/index';

const routes = [
    {
        path: "/",
        name: "home",
        icon: "bx bx-bar-chart",
        exact: true,
        component: Home,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Inicio",
            permission_required: [
                "view_quotations",
            ],
        },
    },
    {
        path: "/cotizaciones",
        name: "cotizaciones",
        icon: "bx bxs-label",
        exact: true,
        component: QuotationsList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Cotizaciones",
            permission_required: [
                "view_quotations",
            ],
        },
    },
    {
        path: "/cotizacion/:id",
        name: "cotizaciones",
        icon: "bx bxs-certification",
        exact: false,
        component: UpdateQuotation,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Cotización",
            permission_required: [
                "change_quotations",
            ],
        },
    },
    {
        path: "/fabricaciones",
        name: "fabricaciones",
        icon: "bx bxs-label",
        exact: true,
        component: OrdersList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Fabricaciones",
            permission_required: [
                "view_orders",
            ],
        },
    },
    {
        path: "/fabricacion/:id",
        name: "fabricaciones",
        icon: "bx bxs-certification",
        exact: false,
        component: ItemOrder,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Fabricación",
            permission_required: [
                "view_orders",
            ],
        },
    },
    {
        path: "/catalogo",
        name: "catalogo",
        icon: "bx bxs-map-alt",
        exact: true,
        component: CataloguesList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Catálago",
            permission_required: [
                "view_items",
            ],
        },
    },
    {
        path: "/catalogo/item/:nombre/:id",
        name: "catalogo",
        icon: "bx bxs-certification",
        exact: false,
        component: ItemCatalogue,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Catálago",
            permission_required: [
                "view_items",
            ],
        },
    },
    {
        path: "/catalogo/crear/item/:nombre/:id",
        name: "catalogo",
        icon: "bx bxs-certification",
        exact: false,
        component: CreateItem,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Catálago",
            permission_required: [
                "add_items",
            ],
        },
    },
    {
        path: "/catalogo/editar/item/:nombre/:id",
        name: "catalogo",
        icon: "bx bxs-certification",
        exact: false,
        component: UpdateItem,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Catálago",
            permission_required: [
                "change_items",
            ],
        },
    },
    {
        path: "/familias",
        name: "familias",
        icon: "bx bx-circle",
        exact: true,
        component: FamiliesList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Familias",
            permission_required: [
                "view_families",
            ],
        },
    },
    {
        path: "/familia/crear",
        name: "familias",
        icon: "bx bx-circle",
        exact: false,
        component: FamilyCreate,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Familias",
            permission_required: [
                "add_families",
            ],
        },
    },
    {
        path: "/familia/editar/:id",
        name: "familias",
        icon: "bx bx-circle",
        exact: false,
        component: FamilyUpdate,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Familias",
            permission_required: [
                "view_families",
            ],
        },
    },
    {
        path: "/categorias",
        name: "categorias",
        icon: "bx bxs-duplicate",
        exact: true,
        component: CategoriesList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Categorías",
            permission_required: [
                "view_categories",
            ],
        },
    },
    {
        path: "/categoria/crear",
        name: "categorias",
        icon: "bx bxs-duplicate",
        exact: false,
        component: CategoryCreate,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Categorías",
            permission_required: [
                "add_categories",
            ],
        },
    },
    {
        path: "/categoria/editar/:id",
        name: "categorias",
        icon: "bx bxs-duplicate",
        exact: false,
        component: CategoryUpdate,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Categoría",
            permission_required: [
                "view_categories",
            ],
        },
    },
    {
        path: "/atributos",
        name: "atributos",
        icon: "bx bxs-file-blank",
        exact: true,
        component: AttributesList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Atributos",
            permission_required: [
                "view_attributes",
            ],
        },
    },
    {
        path: "/atributo/crear",
        name: "atributo",
        icon: "bx bxs-file-blank",
        exact: false,
        component: AttributeCreate,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Atributos",
            permission_required: [
                "add_attributes",
            ],
        },
    },
    {
        path: "/atributo/editar/:id",
        name: "atributos",
        icon: "bx bxs-file-blank",
        exact: false,
        component: AttributeUpdate,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Atributos",
            permission_required: [
                "view_attributes",
            ],
        },
    },
    {
        path: "/generos",
        name: "generos",
        icon: "bx bx-bookmark",
        exact: true,
        component: GendersList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Géneros",
            permission_required: [
                "view_genders",
            ],
        },
    },
    {
        path: "/fabricantes",
        name: "fabricantes",
        icon: "bx bx-walk",
        exact: true,
        component: ManufacturersList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Fabricantes",
            permission_required: [
                "view_manufacturers",
            ],
        },
    },
    {
        path: "/ciudades",
        name: "ciudades",
        icon: "bx bxs-city",
        exact: true,
        component: CitiesList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Ciudades",
            permission_required: [
                "view_cities",
            ],
        },
    },
    {
        path: "/tipos-pagos",
        name: "tipos-pagos",
        icon: "bx bx-dollar-circle",
        exact: true,
        component: TypePaymentList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Tipos de pagos",
            permission_required: [
                "view_typepayment",
            ],
        },
    },
    {
        path: "/comercios",
        name: "comercios",
        icon: "bx  bxs-store",
        exact: true,
        component: ShopList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Comercios",
            permission_required: [
                "view_shop",
            ],
        },
    },
    {
        path: "/cuentas",
        name: "cuentas",
        icon: "bx bx-walk",
        exact: true,
        component: BankAccountsList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Cuentas",
            permission_required: [
                "view_bankaccounts",
            ],
        },
    },
    {
        path: "/imagenes",
        name: "imagenes",
        icon: "bx bxs-image-alt",
        exact: true,
        component: BannerImagesList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Imágenes",
            permission_required: [
                "view_bannerimages",
            ],
        },
    },
    {
        path: "/usuarios",
        name: "usuarios",
        icon: "bx bxs-user",
        exact: true,
        component: Users,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Usuarios",
            permission_required: [
                "view_user",
            ],
        },
    },
    {
        path: "/usuarios/crear",
        name: "usuarios",
        icon: "bx bxs-user",
        exact: true,
        component: CreateUser,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Usuarios",
            permission_required: [
                "add_user",
            ],
        },
    },
    {
        path: "/usuarios/editar/:id",
        name: "usuarios",
        icon: "bx bxs-user",
        exact: true,
        component: UpdateUser,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Usuarios",
            permission_required: [
                "change_user",
            ],
        },
    },
    {
        path: "/clientes",
        name: "clientes",
        icon: "bx bx-user-circle",
        exact: true,
        component: Clients,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Clientes",
            permission_required: [
                "view_user",
            ],
        },
    },
    {
        path: "/roles",
        name: "roles",
        icon: "bx bxs-group",
        exact: true,
        component: GroupList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Roles",
            permission_required: [
                "view_group",
            ],
        },
    },
    {
        path: "/roles/crear",
        name: "roles",
        icon: "bx bxs-group",
        exact: true,
        component: CreateGroup,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Roles",
            permission_required: [
                "add_group",
            ],
        },
    },
    {
        path: "/roles/editar/:id",
        name: "roles",
        icon: "bx bxs-group",
        exact: true,
        component: UpdateGroup,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Roles",
            permission_required: [
                "change_group",
            ],
        },
    },
];

export default routes;
