import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../../components/breadcrumbs";
import Footer from "../../../components/footer";
import {useHistory} from "react-router-dom";
import {Chart} from "react-google-charts";
import {useSelector} from "react-redux";

const Home = (props) => {
    let history = useHistory();

    const _get_values_home_clients = useSelector((state) => state.users_module._get_values_home_clients);
    const _get_values_home_quotations = useSelector((state) => state.quotation_module._get_values_home_quotations);

    const [permisos, setPermisos] = useState([]);
    const [data_clients, setDataClients] = useState([]);
    const [data_quotations, setDataQuotations] = useState([]);


    useEffect(() => {
        if (_get_values_home_clients.data) {
            setDataClients(_get_values_home_clients.data);
        }
    }, [_get_values_home_clients])

    useEffect(() => {
        if (_get_values_home_quotations.data) {
            setDataQuotations(_get_values_home_quotations.data);
        }
    }, [_get_values_home_quotations])

    const options_clients = {
        chart: {
            title: 'Clientes',
        },
        colors: ['#1C8290'],
    };

    const options_quotations = {
        chart: {
            title: 'Cotizaciones',
        },
        colors: ['#219DAD'],
    };

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);
        }
    }, [props]);

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Fabricaciones"
                            items={[{label: "Inicio", link: "/"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="col-12 mb-2">
                                    <p className="type_text text_color text_inter text_32">Te damos la bienvenida a la
                                        plataforma de fabricaciones Chile</p>
                                    <p className="type_text text_black text_inter text_16">A continuación te mostramos
                                        un resumen de lo que ha sucedido recientemente</p>
                                    <div className="row mt-2">
                                        <div className="col-md-6 col-12">
                                            <p className="type_text text_black text_inter text_16">Nuevos clientes
                                                registrados</p>
                                        </div>
                                        <div className="col-md-6 col-12 text-right">
                                            {
                                                permisos.filter(x => x.codigo === 'view_user').length > 0 && (
                                                    <button className="btn btn-calendar" type="button" onClick={() => {
                                                        history.push(`/clientes`)
                                                    }}>Ver lista completa</button>
                                                )
                                            }
                                        </div>
                                        <div className="col-12 mt-3" style={{marginLeft: '10%'}}>
                                            {data_clients.length > 0 &&
                                                <Chart
                                                    chartType="Bar"
                                                    width="80%"
                                                    height="244px"
                                                    data={data_clients}
                                                    options={options_clients}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-md-6 col-12">
                                            <p className="type_text text_black text_inter text_16">Nuevas cotizaciones
                                                registradas</p>
                                        </div>
                                        <div className="col-md-6 col-12 text-right">
                                            {
                                                permisos.filter(x => x.codigo === 'view_quotations').length > 0 && (
                                                    <button className="btn btn-calendar" type="button" onClick={() => {
                                                        history.push(`/cotizaciones`)
                                                    }}>Ver lista completa</button>
                                                )
                                            }
                                        </div>
                                        <div className="col-12 mt-3" style={{marginLeft: '10%'}}>
                                            {data_quotations.length > 0 &&
                                                <Chart
                                                    chartType="Bar"
                                                    width="80%"
                                                    height="244px"
                                                    data={data_quotations}
                                                    options={options_quotations}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Home;
