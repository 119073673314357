import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {
    clear_status_attribute,
    CREATE_ATTRIBUTE_SUCCESS,
    create_attribute,
    get_all_attribute
} from "../../../redux/attributes";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../../components/footer";
import {ReactComponent as ReactCancel} from '../../../cancel.svg';

const CreateAttribute = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const _create_attribute = useSelector((state) => state.attribute_module._create_attribute);
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [form, setForm] = useState({
        nombre: "",
    });

    useEffect(() => {
        if (_create_attribute.status === 201) {
            dispatch(clear_status_attribute(CREATE_ATTRIBUTE_SUCCESS));
            history.push("/atributos");
            dispatch(get_all_attribute(token));
        } else if (_create_attribute.status !== 0) {
            dispatch(clear_status_attribute(CREATE_ATTRIBUTE_SUCCESS));
            toast.error(_create_attribute.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_create_attribute, dispatch, history, token]);


    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate fields

        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        dispatch(create_attribute(token, form));
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Crear"
                            items={[
                                {label: "Lista", link: "/atributos"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <div className="d-flex">
                                        <div style={{marginTop: 5, cursor: 'pointer'}} onClick={() => {
                                            history.push(`/atributos`);
                                        }}>
                                            <ReactCancel width={32} heght={29}/>
                                        </div>
                                        <p className="type_text text_color text_inter text_32">Crear atributo</p>
                                    </div>
                                    <p className="type_text text_black text_inter text_16">Escribe el nombre del
                                        atributo a crear</p>
                                </div>
                                <div className="card-body">
                                    <div><ToastContainer/></div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                label="nombre"
                                                name="nombre"
                                                value={form.nombre}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/atributos" className="btn btn-outline-dark m-1">
                                        Cancelar
                                    </Link>
                                    <Button type="submit" text="Guardar" theme="calendar"/>
                                </div>
                            </form>
                        </div>
                    </div>
                    useParams
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default CreateAttribute;
