import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {ReactComponent as ReactCancel} from '../../../cancel.svg';
import {PAY_ORDER_DOWNLOAD_PDF_PATH, URLAPI} from "../../../config";
import {
    CONFIRM_QUOTATION_SUCCESS,
    confirm_quotation,
    clear_status_quotation,
    update_quotation_client,
    UPDATE_QUOTATION_CLIENT_SUCCESS,
    get_all_quotation,
    advance_payment_quotation,
    ADVANCE_PAYMENT_QUOTATION_SUCCESS
} from "../../../redux/quotation";
import {get_all_shopping_cart} from "../../../redux/shopping_carts";
import {get_all_order} from "../../../redux/order";
import LoadingSpinner from "../../../components/loading-spinner";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Modal from "../../../components/modal";
import Input from "../../../components/input";


const ItemQuotation = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {id} = useParams();

    const [isLoading, setIsLoading] = useState(false);

    const _get_all_quotation = useSelector((state) => state.quotation_module._get_all_quotation);
    const _confirm_quotation = useSelector((state) => state.quotation_module._confirm_quotation);
    const _advance_payment_quotation = useSelector((state) => state.quotation_module._advance_payment_quotation);
    const _update_quotation_client = useSelector((state) => state.quotation_module._update_quotation_client);
    const {token} = useSelector((state) => state.users_module.login.data) || null;


    const [data, setData] = useState({
        fecha_creacion: "",
        codigo_interno: "",
        estado: "",
        costo: 0,
        valor_anticipo: 0,
        total: 0,
        ciudad: "",
        direccion: "",
        usuario: {
            id: 0,
            identificacion: "",
            nombres: "",
            apellidos: "",
            correo: "",
            telefono: ""
        },
        detalles: []
    });

    const [config, setConfig] = useState({
        moneda: '',
        cantidad_decimal: '',
        separador_decimal: '',
        porcentaje_anticipo: 0,
    });

    useEffect(() => {
        if (_get_all_quotation.data && _get_all_quotation.data.datos) {
            let value = _get_all_quotation.data.datos.filter(x => parseInt(x.id) === parseInt(id));
            if (value.length > 0) {
                setData(value[0]);
            }
            setConfig(_get_all_quotation.data.configuraciones);
        }
    }, [_get_all_quotation, id]);

    useEffect(() => {
        if (_confirm_quotation.status === 200) {
            dispatch(clear_status_quotation(CONFIRM_QUOTATION_SUCCESS));
            dispatch(get_all_quotation(token, 1));
            /* history.push(`/mis-cotizaciones`); */
            setIsLoading(false);
            setModalConfirm(false);
        } else if (_confirm_quotation.status !== 0) {
            dispatch(clear_status_quotation(CONFIRM_QUOTATION_SUCCESS));
            toast.error(_confirm_quotation.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_confirm_quotation, dispatch, token, history, id]);

    useEffect(() => {
        if (_update_quotation_client.status === 200) {
            dispatch(clear_status_quotation(UPDATE_QUOTATION_CLIENT_SUCCESS));
            setIsLoading(false);
            dispatch(get_all_quotation(token, 1));
            dispatch(get_all_shopping_cart(token));
            document.getElementById("catalogo").className = "type_text text_black text_16";
            document.getElementById("cotizaciones").className = "type_text text_black text_16";
            document.getElementById("carrito").className = "type_text text_black text_16 active";
            document.getElementById("fabricaciones").className = "type_text text_black text_16";
            history.push(`/carrito-compra`);

        } else if (_update_quotation_client.status !== 0) {
            dispatch(clear_status_quotation(UPDATE_QUOTATION_CLIENT_SUCCESS));
            toast.error(_update_quotation_client.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_update_quotation_client, token, dispatch, history, id]);

    const [modalConfirm, setModalConfirm] = useState(false);
    const [modalPayment, setModalPayment] = useState(false);
    const [modalMessagePayment, setModalMessagePayment] = useState(false);
    const [file, setFile] = useState('');

    useEffect(() => {
        if (_advance_payment_quotation.status === 200) {
            dispatch(clear_status_quotation(ADVANCE_PAYMENT_QUOTATION_SUCCESS));
            setIsLoading(false);
            dispatch(get_all_quotation(token, 1));
            dispatch(get_all_order(token, 1));
            setModalPayment(false);
            setModalMessagePayment(true);
        } else if (_advance_payment_quotation.status !== 0) {
            dispatch(clear_status_quotation(ADVANCE_PAYMENT_QUOTATION_SUCCESS));
            toast.error(_advance_payment_quotation.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_advance_payment_quotation, dispatch, token, history, id]);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate fields

        if (file === "") {
            toast.error("Debe seleccionar un comprobante", {position: toast.POSITION.TOP_RIGHT});
            return;
        }

        let advance = parseFloat(data.total) * parseFloat(config.porcentaje_anticipo) / 100;

        let datas = new FormData();
        datas.append(`comprobante`, file);
        datas.append(`anticipo`, advance);
        setIsLoading(true);

        dispatch(advance_payment_quotation(token, id, datas))

    }

    return (
        <div className="color box_shadow">
            {isLoading && <LoadingSpinner/>}
            <div><ToastContainer/></div>
            <div className="p-1">
                <div className="card-body">
                    <div className="d-flex">
                        <div style={{marginRight: 10, cursor: 'pointer'}} onClick={() => {
                            history.push(`/mis-cotizaciones`);
                        }}>
                            <ReactCancel width={32} heght={29}/>
                        </div>
                        <p className="type_text text_24 text_black"
                           style={{marginTop: 2, fontWeight: 'bold'}}>{data.codigo}</p>
                    </div>

                    <div className="row">
                        <div className="col-12 mt-2">
                            <p className="type_text text_16 text_black">Fecha de creación: {data.fecha_creacion}</p>
                            <p className="type_text text_16 text_black">Estado: {data.estado}</p>
                        </div>
                        {(data.estado === 'En espera') ? (
                            <div className="col-12 mt-2">
                                <div className="border_card_1 p-1">
                                    <p className="type_text text_16 text_black mt-1">Hemos recibido tu cotización,
                                        nuestros asesores la responderán lo más pronto posible</p>
                                </div>
                            </div>
                        ) : (
                            (data) && (
                                <>
                                    {(data.estado === 'Pendiente pago') && (
                                        <div className="col-12 mt-2">
                                            <div className="border_card_2 p-1">
                                                <p className="type_text text_16 text_white mt-1">Tienes pendiente el
                                                    pago del anticipo, por favor carga el comprobante una vez hayas
                                                    realizado el pago. Puedes ver la información del pago en tu correo o
                                                    descargar la orden de pago desde esta pantalla.</p>
                                            </div>
                                        </div>
                                    )}
                                    {(data.estado === 'Orden generada') && (
                                        <div className="col-12 mt-2">
                                            <div className="border_card_2 p-1">
                                                <p className="type_text text_16 text_white mt-1">Nos encontramos en el
                                                    proceso de fabricación de tus joyas, te informaremos por correo
                                                    electrónico cuando estén listas para retirar.</p>
                                            </div>
                                        </div>
                                    )}
                                    {data.detalles.map((x) => (
                                        <div className="col-12 mt-2">
                                            <div className="border_card_1 p-1">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-12 col-sm-12 text-center">
                                                        {(x.imagenes.length > 0) &&
                                                            <img
                                                                src={URLAPI + x.imagenes[0].url}
                                                                className="img-item"
                                                                alt='Imagen'
                                                            />
                                                        }
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                                        <p className="type_text text_32 text_color">{x.titulo}</p>
                                                        <p className="type_text text_16 text_black">{x.descripcion}</p>
                                                        <p className="type_text text_16 text_black">Código: {x.codigo_interno}</p>
                                                        {(x.valores) && x.valores.map((y) => (
                                                            <div className="mt-1 pr-md-5 pr-0">
                                                                <p className="type_text text_16 text_black mb-0">{y.atributo}</p>
                                                                <p className="type_text text_12 text_black div_detail"
                                                                   style={{padding: '7px 10px'}}>{y.valor}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                                        <p className="type_text text_32 text_color">Valor cotizado</p>
                                                        <table width={'98%'}>
                                                            <tr>
                                                                <th className="type_text text_20 text_black"
                                                                    width={'50%'}>Cantidad
                                                                </th>
                                                                <th className="type_text text_20 text_black text-right"
                                                                    width={'50%'}>{x.cantidad}</th>
                                                            </tr>
                                                            <tr>
                                                                <th className="type_text text_20 text_black pt-1">Precio</th>
                                                                <th className="type_text text_20 text_black pt-1 text-right">{config.moneda} {parseFloat(x.precio).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</th>
                                                            </tr>
                                                            <tr>
                                                                <th className="type_text texto text_black pt-3">Subtotal</th>
                                                                <th className="type_text texto text_black pt-3 text-right">{config.moneda} {parseFloat(x.subtotal).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</th>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    ))}
                                    <div className="col-12 mt-2">
                                        <table width={'98%'}>
                                            <tr>
                                                <th className="th-width"></th>
                                                <th className="type_text text_20 text_black mb-1 td-width">Anticipo de
                                                    fabricacion
                                                </th>
                                                <th className="type_text text_20 text_black mb-1 text-right pr-md-3 pr-1">{config.moneda} {parseFloat(data.valor_anticipo).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</th>
                                            </tr>
                                            <tr>
                                                <th className="th-width"></th>
                                                <td colSpan={2} className="type_text text_20 text_black mb-1 td-width">
                                                    <small>Corresponde al {config.porcentaje_anticipo}% del total de la
                                                        fabricación, depósito o transferencia al confirmar la
                                                        fabricación</small></td>
                                            </tr>
                                            <tr>
                                                <th className="th-width"></th>
                                                <th className="type_text text_20 text_black mb-1 td-width">Envio</th>
                                                <th className="type_text text_20 text_black mb-1 text-right pr-md-3 pr-1">{config.moneda} {parseFloat(data.costo).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</th>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                <th className="type_text texto text_black pt-1 mb-1">Total</th>
                                                <th className="type_text texto text_black pt-1 mb-1 text-right pr-md-3 pr-1">{config.moneda} {parseFloat(data.total).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</th>
                                            </tr>
                                        </table>
                                    </div>
                                    {(data.estado === 'Cotizado') && (
                                        <div className="col-12 text-right mt-2">
                                            <button className="btn btn-outline-calendar-cliente1" style={{margin: 1}}
                                                    onClick={() => {
                                                        setIsLoading(true);
                                                        dispatch(update_quotation_client(token, id, {}));
                                                    }}>Editar pedido
                                            </button>
                                            <button className="btn btn-calendar-cliente1" style={{margin: 1}}
                                                    onClick={() => {
                                                        setModalConfirm(true);
                                                    }}>Confirmar fabricación
                                            </button>
                                        </div>
                                    )}
                                    {(data.estado === 'Pendiente pago') && (
                                        <div className="col-12 text-right mt-2">
                                            {/* eslint-disable-next-line */}
                                            <a href={`${URLAPI}${PAY_ORDER_DOWNLOAD_PDF_PATH}${id}/`} target="_blank"
                                               className="btn btn-outline-calendar-cliente1" style={{margin: 1}}>Descagar
                                                orden de pago</a>
                                            <button className="btn btn-calendar-cliente1" style={{margin: 1}}
                                                    onClick={() => {
                                                        setModalPayment(true);
                                                    }}>Cargar comprobante
                                            </button>
                                        </div>
                                    )}
                                    {(data.estado === 'Orden generada') && (
                                        <div className="col-12 text-right mt-2">
                                            <button className="btn btn-outline-calendar-cliente1" style={{margin: 1}}
                                                    onClick={() => {
                                                        history.push(`/mis-cotizaciones`);
                                                    }}>Atras
                                            </button>
                                            <button className="btn btn-calendar-cliente1" style={{margin: 1}}
                                                    onClick={() => {
                                                        document.getElementById("catalogo").className = "type_text text_black text_16";
                                                        document.getElementById("cotizaciones").className = "type_text text_black text_16";
                                                        document.getElementById("fabricaciones").className = "type_text text_black text_16 active";
                                                        document.getElementById("carrito").className = "type_text text_black text_16";
                                                        history.push(`/mi-fabricacion/` + data.orden);
                                                    }}>Ver orden de fabricación
                                            </button>
                                        </div>
                                    )}
                                </>
                            )
                        )}
                    </div>
                </div>
            </div>
            <Modal className="modal-main div-modal" show={modalConfirm}>
                {isLoading && <LoadingSpinner/>}
                <div className="card-body">
                    <h5 className="titulo_modal type_text mb-2">Confirmar fabricación</h5>
                    <div className="text-center">
                        <p className="type_text text_16 text_black">Al confirmar la fabricación se generará una orden de
                            pago de anticipo, se enviará la orden a tu correo electrónico</p>
                    </div>
                </div>
                <div className="card-footer text-center">
                    <button type="button" onClick={() => {
                        setIsLoading(true);
                        dispatch(confirm_quotation(token, id, {}));
                    }} className={`btn btn-calendar-cliente1 mb-1 button_div`}>Confirmar
                    </button>
                    <button type="button" onClick={() => {
                        setModalConfirm(false);
                    }} className={`btn btn-outline-calendar-cliente1 button_div`}>Cancelar
                    </button>
                </div>
            </Modal>
            <Modal className="modal-main div-modal" show={modalPayment}>
                {isLoading && <LoadingSpinner/>}
                <form className="card" onSubmit={handleSubmit}>
                    <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">Cargar comprobante</h5>
                        <Input
                            name="file"
                            label="Archivo"
                            type="file"
                            onChange={handleFileChange}
                        />
                    </div>
                    <div className="card-footer text-center">
                        <button type="submit" className={`btn btn-calendar-cliente1 mb-1 button_div`}>Cargar</button>
                        <button type="button" onClick={() => {
                            setModalPayment(false);
                        }} className={`btn btn-outline-calendar-cliente1 button_div`}>Cancelar
                        </button>
                    </div>
                </form>
            </Modal>
            <Modal className="modal-main div-modal" show={modalMessagePayment}>
                <div className="card-body">
                    <h5 className="titulo_modal type_text mb-2">Comprobante cargado</h5>
                    <div className="text-center">
                        <p className="type_text text_16 text_black">Validaremos el pago y te informaremos sobre el
                            proceso de fabricación de tus joyas, por favor revisa tu correo electrónico donde te
                            enviaremos toda la información.</p>
                    </div>
                </div>
                <div className="card-footer text-center">
                    <button type="button" onClick={() => {
                        history.push(`/mis-cotizaciones`);
                        setModalMessagePayment(false);
                    }} className={`btn btn-calendar-cliente1 mb-1 button_div`}>Entendido
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default ItemQuotation;
