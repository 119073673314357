import axios from "axios";
import {
    URLAPI,
    QUOTATION_ADMIN_PATH,
    QUOTATION_PATH,
    QUOTATION_CREATE_PATH,
    QUOTATION_UPDATE_PATH,
    QUOTATION_UPDATE_CLIENT_PATH,
    QUOTATION_CONFIRM_PATH,
    QUOTATION_REPORT_PATH,
    QUOTATION_REPORT_CHECK_PATH,
    VALUES_QUOTATIONS_PATH,
    QUOTATION_ADVANCE_PAYMENT_PATH,
    QUOTATION_CONFIRM_ADVANCE_PAYMENT_PATH,
    QUOTATION_CANCEL_ADVANCE_PAYMENT_PATH,
} from "../config";
import {LOGOUT} from './users'

const init = {
    _get_all_quotation: {
        data: [],
        status: 0,
        message: {},
    },
    _get_all_quotation_admin: {
        data: [],
        status: 0,
        message: {},
    },
    _get_values_home_quotations: {
        data: [],
        status: 0,
        message: {},
    },
    _create_quotation: {
        data: {},
        status: 0,
        message: {},
    },
    _update_quotation: {
        data: {},
        status: 0,
        message: {},
    },
    _update_quotation_client: {
        data: {},
        status: 0,
        message: {},
    },
    _confirm_quotation: {
        data: {},
        status: 0,
        message: {},
    },
    _advance_payment_quotation: {
        data: {},
        status: 0,
        message: {},
    },
    _confirm_advance_payment_quotation: {
        data: {},
        status: 0,
        message: {},
    },
    _cancel_advance_payment_quotation: {
        data: {},
        status: 0,
        message: {},
    },
    _get_notification: {
        data: [],
    },
    _get_report: {
        data: {},
        status: 0,
        message: ""
    },
}

const GET_ALL_QUOTATION_SUCCESS = "GET_ALL_QUOTATION_SUCCESS";
const GET_ALL_QUOTATION_ADMIN_SUCCESS = "GET_ALL_QUOTATION_ADMIN_SUCCESS";
const GET_VALUES_HOME_QUOTATIONS = "GET_VALUES_HOME_QUOTATIONS";
export const CREATE_QUOTATION_SUCCESS = "CREATE_QUOTATION_SUCCESS";
export const UPDATE_QUOTATION_SUCCESS = "UPDATE_QUOTATION_SUCCESS";
export const UPDATE_QUOTATION_CLIENT_SUCCESS = "UPDATE_QUOTATION_CLIENT_SUCCESS";
export const CONFIRM_QUOTATION_SUCCESS = "CONFIRM_QUOTATION_SUCCESS";
export const ADVANCE_PAYMENT_QUOTATION_SUCCESS = "ADVANCE_PAYMENT_QUOTATION_SUCCESS";
export const CONFIRM_ADVANCE_PAYMENT_QUOTATION_SUCCESS = "CONFIRM_ADVANCE_PAYMENT_QUOTATION_SUCCESS";
export const CANCEL_ADVANCE_PAYMENT_QUOTATION_SUCCESS = "CANCEL_ADVANCE_PAYMENT_QUOTATION_SUCCESS";
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_REPORT = "GET_REPORT"


export const quotation_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_QUOTATION_SUCCESS:
            return {
                ...state,
                _get_all_quotation: action.payload,
            };
        case GET_ALL_QUOTATION_ADMIN_SUCCESS:
            return {
                ...state,
                _get_all_quotation_admin: action.payload,
            };
        case GET_VALUES_HOME_QUOTATIONS:
            return {
                ...state,
                _get_values_home_quotations: action.payload,
            };
        case CREATE_QUOTATION_SUCCESS:
            return {
                ...state,
                _create_quotation: action.payload,
            };
        case UPDATE_QUOTATION_SUCCESS:
            return {
                ...state,
                _update_quotation: action.payload,
            };
        case UPDATE_QUOTATION_CLIENT_SUCCESS:
            return {
                ...state,
                _update_quotation_client: action.payload,
            };
        case CONFIRM_QUOTATION_SUCCESS:
            return {
                ...state,
                _confirm_quotation: action.payload,
            };
        case ADVANCE_PAYMENT_QUOTATION_SUCCESS:
            return {
                ...state,
                _advance_payment_quotation: action.payload,
            };
        case CONFIRM_ADVANCE_PAYMENT_QUOTATION_SUCCESS:
            return {
                ...state,
                _confirm_advance_payment_quotation: action.payload,
            };
        case CANCEL_ADVANCE_PAYMENT_QUOTATION_SUCCESS:
            return {
                ...state,
                _cancel_advance_payment_quotation: action.payload,
            };
        case GET_NOTIFICATION:
            return {
                ...state,
                _get_notification: action.payload,
            };

        case GET_REPORT:
            return {
                ...state,
                _get_report: action.payload
            }
        case LOGOUT:
            return init;
        default:
            return state;
    }
}

export const get_all_quotation = (token, page) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${QUOTATION_PATH}?page=${page}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_QUOTATION_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: GET_ALL_QUOTATION_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.message,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_QUOTATION_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Ocurrió un error",
                },
            });
        }
    }
}

export const get_all_quotation_admin = (token, page) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${QUOTATION_ADMIN_PATH}?page=${page}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_QUOTATION_ADMIN_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: GET_ALL_QUOTATION_ADMIN_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.message,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_QUOTATION_ADMIN_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Ocurrió un error",
                },
            });
        }
    }
}


export const get_values_home_quotations = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${VALUES_QUOTATIONS_PATH}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_VALUES_HOME_QUOTATIONS,
            payload: {
                data: response.data.datos,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: GET_VALUES_HOME_QUOTATIONS,
                payload: {
                    data: [],
                    status: error.response.status,
                    message: error.response.data.message,
                },
            });
        } else {
            dispatch({
                type: GET_VALUES_HOME_QUOTATIONS,
                payload: {
                    data: [],
                    status: 500,
                    message: "Error al obtener los datos",
                },
            });
        }
    }
};


export const create_quotation = (token, data) => async (dispatch) => {
    try {
        console.log(data);

        const response = await axios.post(`${URLAPI}${QUOTATION_CREATE_PATH}`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: CREATE_QUOTATION_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: CREATE_QUOTATION_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_QUOTATION_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al crear",
                },
            });
        }
    }
}

export const update_quotation = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(`${URLAPI}${QUOTATION_UPDATE_PATH}${id}/`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: UPDATE_QUOTATION_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response.data != null) {
            dispatch({
                type: UPDATE_QUOTATION_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_QUOTATION_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
}

export const update_quotation_client = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(`${URLAPI}${QUOTATION_UPDATE_CLIENT_PATH}${id}/`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: UPDATE_QUOTATION_CLIENT_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response.data != null) {
            dispatch({
                type: UPDATE_QUOTATION_CLIENT_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_QUOTATION_CLIENT_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
}

export const confirm_quotation = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(`${URLAPI}${QUOTATION_CONFIRM_PATH}${id}/`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: CONFIRM_QUOTATION_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response.data != null) {
            dispatch({
                type: CONFIRM_QUOTATION_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CONFIRM_QUOTATION_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
}

export const advance_payment_quotation = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(`${URLAPI}${QUOTATION_ADVANCE_PAYMENT_PATH}${id}/`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: ADVANCE_PAYMENT_QUOTATION_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response.data != null) {
            dispatch({
                type: ADVANCE_PAYMENT_QUOTATION_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: ADVANCE_PAYMENT_QUOTATION_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
}

export const confirm_advance_payment_quotation = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(`${URLAPI}${QUOTATION_CONFIRM_ADVANCE_PAYMENT_PATH}${id}/`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: CONFIRM_ADVANCE_PAYMENT_QUOTATION_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response.data != null) {
            dispatch({
                type: CONFIRM_ADVANCE_PAYMENT_QUOTATION_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CONFIRM_ADVANCE_PAYMENT_QUOTATION_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
}

export const cancel_advance_payment_quotation = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(`${URLAPI}${QUOTATION_CANCEL_ADVANCE_PAYMENT_PATH}${id}/`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: CANCEL_ADVANCE_PAYMENT_QUOTATION_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response.data != null) {
            dispatch({
                type: CANCEL_ADVANCE_PAYMENT_QUOTATION_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CANCEL_ADVANCE_PAYMENT_QUOTATION_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
}

export const get_report = (data, token) => async (dispatch) => {
    try {
        const res = await axios.post(URLAPI + QUOTATION_REPORT_PATH, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        })

        dispatch({
            type: GET_REPORT,
            payload: {
                data: res.data,
                status: res.status,
                message: ""
            }
        })
    } catch (e) {
        dispatch({
            type: GET_REPORT,
            payload: {
                data: {},
                status: 500,
                message: "Ocurrio un error al generar el reporte"
            }
        })
    }
}

export const save_notification = (data) => async (dispatch) => {

    dispatch({
        type: GET_NOTIFICATION,
        payload: {
            data: data,
        }
    });
}

export const check_export_status = async (token, task_id) => {
    return await axios.get(`${URLAPI}${QUOTATION_REPORT_CHECK_PATH}${task_id}/`, {
        headers: {
            Authorization: `Basic ${token}`,
        },
    })
}


export const clear_status_quotation = (type) => async dispatch => {
    dispatch({
        type: type,
        payload: {
            data: {},
            status: 0,
            message: {},
        },
    });
}
