import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Footer from "../../../components/footer";
import {ReactComponent as ReactLogo} from '../../../eclipse.svg';
import {URLAPI} from "../../../config";


const Catalogues = (props) => {
    let history = useHistory();
    const families = useSelector(
        (state) => state.family_module._get_all_family
    );

    const [data, setData] = useState([]);

    useEffect(() => {
        if (families.data && families.data.datos) {
            let values = families.data.datos.filter((x) => x.activo === true);
            setData(values);
        }
    }, [families]);


    /* const [permisos, setPermisos] = useState([]);

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);

        }
    }, [props]);
 */

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-3">
                        <Breadcrumbs
                            title="Catálogo"
                            items={[{label: "Lista", link: "/catalogo"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="col-12 mb-2">
                                    <p className="type_text text_color text_inter text_32">Lista de productos de
                                        catálogo</p>
                                    <p className="type_text text_black text_inter text_16">Primero elige una familia</p>
                                    <div className="row">
                                        {data.length > 0 ? (
                                            <>
                                                {/* {
                                                    permisos.filter(x => x.codigo === 'add_families').length > 0 && (
                                                        <div className="col-md-3 col-sm-6 col-12 text-center mt-1">
                                                            <div className="border_card" onClick={() => {history.push(`/familia/crear/`);}}>
                                                                <div className="mt-1 mb-1">
                                                                    <ReactLogo width={150} heght={150}/>
                                                                </div>
                                                                <p className="type_text text_black text_inter text_24">Nueva</p>
                                                            </div>
                                                        </div>
                                                    )
                                                } */}
                                                {data.map((item) => (
                                                    <div className="col-md-3 col-sm-6 col-12 text-center mt-1">
                                                        <div className="border_card" onClick={() => {
                                                            history.push(`/catalogo/item/${item.nombre}/${item.id}`);
                                                        }}>
                                                            {item.imagen ?
                                                                <div className="mt-1 mb-1">
                                                                    <img
                                                                        className="logo"
                                                                        src={URLAPI + item.imagen}
                                                                        alt="logo"
                                                                        width={150}
                                                                        height={150}
                                                                    />
                                                                </div>
                                                                :
                                                                <div className="mt-1 mb-1">
                                                                    <ReactLogo width={150} heght={150}/>
                                                                </div>
                                                            }
                                                            <p className="type_text text_black text_inter text_24">{item.nombre}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>

                                        ) : (
                                            <div className="col-12 border_card">
                                                <p className="type_text text_black text_inter text_16"
                                                   style={{padding: 10, width: '100%'}}>Aún no tienes ninguna familia
                                                    creada. Empieza creando una familia.</p>
                                                {/* <div className="text-center mb-1">
                                                {
                                                    permisos.filter(x => x.codigo === 'add_families').length > 0 ? 
                                                    <Button  type="button" text="Crear" theme="calendar" onClick={() => {history.push(`/familia/crear/`);}}/> : null
                                                }
                                                </div> */}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Catalogues;
